import { SVGAttributes } from 'react';
import {UntitledIcons, UntitledColorizeIcons, LegacyIcons} from './Icons';

export type UntitledIconNames = keyof typeof UntitledIcons;
export type UntitledColorizeIconNames = keyof typeof UntitledColorizeIcons;
export type LegacyIconNames = keyof typeof LegacyIcons;

interface IconProps extends SVGAttributes<SVGAElement> {
  role?: 'presentation' | 'img';
  className?: string;
  title?: string;
  alt?: string;
}

export interface UntitledColorizeIconProps extends IconProps {
  name: UntitledColorizeIconNames;
  size?: 24 | 32;
}
export interface UntitledIconProps extends IconProps {
  name: UntitledIconNames;
  size?: 24 | 32;
}

export interface LegacyIconProps extends IconProps {
  name: LegacyIconNames;
  size?: 24 | 32 | { width:number, height:number };
}
