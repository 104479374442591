import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {useGlobalState} from "../GlobalState";
import {apiPath} from "../utils/apiUrl";
import {ErrorResponse, UserInfo} from "../interfaces/types";
import {httpPost} from "../utils/fetch-client";
import {AuthenticatorList} from "designsystem/src/components/AuthenticatorList/AuthenticatorList";
import {AuthenticatorListDateProps, AuthenticatorListEntryProps, FidoGroupProps,} from "../../../shared/interfaces";
import {Modal} from "designsystem/src/components/Modal/Modal";
import {ReactSVG} from "react-svg";
import {I18NParagraph} from "designsystem/src/components/I18NParagraph/I18NParagraph";
import {I18NHeading} from "designsystem/src/components/I18NHeading/I18NHeading";
import {OnlyOneAuthInfo} from "designsystem/src/components/Modal/OnlyOneAuthInfo/OnlyOneAuthInfo";
import {Expander} from "designsystem/src/components/Expander/Expander";
import {AuthenticationStatus} from "designsystem/src/components/AuthenticationStatus/AuthenticationStatus";
import {I18NLink} from "designsystem/src/components/I18NLink/I18NLink";
import {identifyPlatformByDeviceName} from "designsystem/src/utils";
import {UserCardPersonal} from "designsystem/src/components/UserCardPersonal/UserCardPersonal";
import {UserCardManaged} from "designsystem/src/components/UserCardManaged/UserCardManaged";
import {useSwitchAccount} from "../components/AccountSwitchHandler";
import {signInFunction} from "./LoginPage";
import {RadioButtonGroup} from "designsystem/src/components/RadioButtonGroup/RadioButtonGroup";
import {StandardRadioButton} from "designsystem/src/components/RadioButton/StandardRadioButton";
import {TextAreaRadioButton} from "designsystem/src/components/RadioButton/TextAreaRadioButton";
import {Heading} from "designsystem/src/components/Heading/Heading";
import {InfoBox} from "designsystem/src/components/InfoBox/InfoBox";
import {Button} from "designsystem/src/components/Button/Button";
import {Avatar} from "designsystem/src/components/Avatar";
import {CardGroup, CardGroupItem,} from "designsystem/src/components/CardGroup/CardGroup";
import {New, Old, Redesign,} from "designsystem/src/components/Redesign/Redesign";
import {Badge} from "designsystem/src/components/Badge/Badge";
import {Spacing} from "designsystem/src/components/Spacing/Spacing";
import {CardGroupHeader} from "designsystem/src/components/CardGroupHeader/CardGroupHeader";
import {useNavigate} from "react-router";
import {useActiveTheme} from "designsystem/src/components/Redesign/activeTheme";
import {navigateToAzureMfaRegistration, navigateToFidoDemo, navigateToFidoRegister, navigateToPasswordReset} from "../utils/navigationToFidoApps";
import {isLessSecureSignIn, useLessSecureSignInsDetails} from "./LessSecureSignIns";

import imageHero from "../assets/images/_success_step_1.svg";
import {useDeleteFido, useFetchDisableAdvancedProtectionPage, useFetchUserFido} from "../utils/fidoHooks";

export interface FidoDataResponse {
  username: string;
  fastSignInReady: boolean;
  signInRegularly: boolean;
  authenticatorsByUpns: ReadonlyArray<AuthenticatorsByUpn>;
  fidoGroupInfo: FidoGroupProps;
}

export interface FidoRenameRequest {
  logicalKeyId: string;
  displayName: string;
}

export interface DeleteFidoRequest {
  logicalKeyId: string;
}

export interface AuthenticatorsByUpn {
  upn: string;
  keys: ReadonlyArray<Fido2Authenticator>;
}

export interface AuthenticatorListEntryPropsByUpn {
  upn: string;
  authenticators: AuthenticatorListEntryProps[];
}

export interface Fido2Authenticator {
  displayName: string;
  modelDisplayName: string;
  logicalKeyId: string;
  aaguid: string;
  active: boolean;
  createDate: string;
  modifyDate: string;
  lastUsedDate: string;
  createdWithRequireResidentKey: boolean;
  recentlyUsed: boolean;
}

export enum REASON_DISABLE_FIDO_ENFORCE {
  GLOBAL_PROTECT = "Global Protect Pre Logon VPN",
  ENROLL_INTUNE_DEVICE = "Enrollment new Intune device",
  OTHER = "Other",
}

export const FidoCredentials: FunctionComponent<{ user: UserInfo }> = ({
  user,
}) => {
  const [, setGlobalError] = useGlobalState("error");
  const switchAccount = useSwitchAccount();
  const { isOldTheme } = useActiveTheme();

  const { t } = useTranslation(["de", "en"]);
  const [fidoData, setFidoData] = useState<{
    username: string;
    fastSignInReady: boolean;
    signInRegularly: boolean;
    ownAuthenticators: AuthenticatorListEntryProps[];
    authenticatorListEntryPropsByUpns: AuthenticatorListEntryPropsByUpn[];
    fidoGroupProps?: FidoGroupProps;
  }>({
    username: "",
    fastSignInReady: false,
    signInRegularly: false,
    ownAuthenticators: [],
    authenticatorListEntryPropsByUpns: [],
    fidoGroupProps: undefined,
  });

  const [cookies] = useCookies();
  const [, setIsLoading] = useGlobalState("isLoading");
  const [modal, setModal] = useGlobalState("modal");
  const [isInFidoMigrationGroupState, setIsInFidoMigrationGroupState] =
    useState<boolean | undefined>(undefined);
  const [
    activeRadioValueDisableFidoEnforceReason,
    setActiveRadioValueDisableFidoEnforceReason,
  ] = useState<undefined | REASON_DISABLE_FIDO_ENFORCE>(undefined);
  const [
    otherReasonDisableFidoEnforcement,
    setOtherReasonDisableFidoEnforcement,
  ] = useState<string>();

  // oldestAllowedDate is 2019 12 31
  const oldestAllowedDate = new Date(1580477894294);

  //this extra loading indicator is for not showing the full side for a redirect (so it does not look like a reload of the side)
  const [initialLoad, setInitialLoad] = useState(false);

  const [lessSecureSignIns] = useLessSecureSignInsDetails();

  const fetchFidoData = useFetchUserFido();

  const updateFidoData = (data: FidoDataResponse) => {
    const authenticatorListEntryPropsByUpns: AuthenticatorListEntryPropsByUpn[] =
      data.authenticatorsByUpns.map((abu) => {
        const authenticatorListEntryProps: AuthenticatorListEntryProps[] =
          abu.keys.map((f2a) => ({
            type: identifyPlatformByDeviceName(f2a.displayName),
            name: f2a.displayName,
            creationDate: validateDateInformation(f2a.createDate),
            lastUsedDate: validateDateInformation(f2a.lastUsedDate),
            deviceType: f2a.modelDisplayName,
            createdWithRequireResidentKey: f2a.createdWithRequireResidentKey,
            recentlyUsed: f2a.recentlyUsed,
            saveNewName:
              abu.upn === user.preferredUsername
                ? (name: string) => renameFido(f2a.logicalKeyId, name)
                : undefined,
            onDelete:
              abu.upn === user.preferredUsername
                ? () => deleteFido(f2a.logicalKeyId)
                : undefined,
            isOnlyAuthenticator: abu.keys.length === 1,
            onCloseModal: onCloseModal,
            testAuthenticator: () => navigateToFidoDemo(abu.upn),
          }));

        return {
          upn: abu.upn,
          authenticators: sortAuthenticators(authenticatorListEntryProps),
        };
      });

    setIsInFidoMigrationGroupState(data?.fidoGroupInfo.isInFidoMigrationGroup);
    setFidoData({
      username: data.username,
      fastSignInReady: data.fastSignInReady,
      signInRegularly: data.signInRegularly,
      ownAuthenticators:
        authenticatorListEntryPropsByUpns.find(
          (v) => v.upn === user.preferredUsername,
        )?.authenticators || [],
      authenticatorListEntryPropsByUpns: authenticatorListEntryPropsByUpns,
      fidoGroupProps: data?.fidoGroupInfo,
    });
  };

  const sortAuthenticators = (
    authenticators: AuthenticatorListEntryProps[],
  ) => {
    return authenticators.sort((a, b) => {
      const date1 = getYoungerDateOfListEntry(a);
      const date2 = getYoungerDateOfListEntry(b);
      return date2.getTime() - date1.getTime();
    });
  };

  const getYoungerDateOfListEntry = (
    entry: AuthenticatorListEntryProps,
  ): Date => {
    // check if creationDate or lastUsedDate is the younger one
    if (entry.creationDate.valid && !entry.lastUsedDate.valid) {
      return new Date(entry.creationDate.date);
    } else if (!entry.creationDate.valid && entry.lastUsedDate.valid) {
      return new Date(entry.lastUsedDate.date);
    } else if (entry.creationDate.valid && entry.lastUsedDate.valid) {
      const val =
        new Date(entry.creationDate.date).getTime() >
        new Date(entry.lastUsedDate.date).getTime()
          ? entry.creationDate
          : entry.lastUsedDate;
      return new Date(val.date);
    }
    // return old date to put entry at the end of the list (has no effect on displayed information)
    return new Date("2000-01-01");
  };

  const validateDateInformation = (dateString: string) => {
    const result: AuthenticatorListDateProps = {
      valid: false,
      i18nKey: "",
      i18nValue: "",
      date: "",
    };
    // check if dateString value is a valid date
    if (isDateValid(dateString)) {
      result.valid = true;
      result.date = dateString;
      const i18nData = dateToAgeString(dateString);
      result.i18nKey = i18nData.key;
      result.i18nValue = i18nData.value || null;
    }
    return result;
  };

  const dateToAgeString = (
    dateString: string,
  ): { key: string; value: string | null } => {
    try {
      // calculate difference in days between currentDate and dateString
      const days = Math.floor(
        (Date.parse(new Date().toISOString()) - Date.parse(dateString)) /
          86400000,
      );
      const sameDay = checkForSameDay(new Date(), new Date(dateString));

      if (days == 0 && sameDay) {
        return { key: "portal.lastSeen.today", value: null };
      } else if (days == 0 && !sameDay) {
        return { key: "portal.lastSeen.yesterday", value: null };
      } else if (days == 1) {
        return { key: "portal.lastSeen.day", value: days.toString() };
      } else if (days <= 13) {
        return { key: "portal.lastSeen.days", value: days.toString() };
      }

      const weeks = Math.floor(days / 7);

      if (weeks <= 5) {
        return { key: "portal.lastSeen.weeks", value: weeks.toString() };
      }

      const months = Math.floor(days / 30);

      if (months == 1) {
        return { key: "portal.lastSeen.month", value: months.toString() };
      }

      return { key: "portal.lastSeen.months", value: months.toString() };
    } catch (e) {
      console.error(e);
    }

    // will end up in dateString being displayed, because it wont be resolved as an i18n key
    return { key: dateString, value: null };
  };

  const checkForSameDay = (firstDate: Date, secondDate: Date) => {
    return firstDate.getDay() === secondDate.getDay();
  };

  const onCloseModal = () => setModal(undefined);

  useEffect(() => {
    const initialLoad = async () => {
      const data = await fetchFidoData();
      const ownAuthenticators = data?.authenticatorsByUpns?.find(
        (v) => v.upn === user.preferredUsername,
      )?.keys;
      if (ownAuthenticators?.length === 0) {
        // skip portal, directly go to passkey registration for own account
        navigateToFidoRegister(user, false);
      } else {
        setInitialLoad(true);
        if (data) {
          updateFidoData(data);
          if (ownAuthenticators?.length === 1 && isOldTheme) {
            setModal({
              type: "info",
              title: t("authenticationInformation.addAnother.headline"),
              children: (
                <OnlyOneAuthInfo
                  authType={identifyPlatformByDeviceName(
                    ownAuthenticators[0].modelDisplayName,
                  )}
                  authName={ownAuthenticators[0].displayName}
                />
              ),
              submitButtonLabel: t(
                "authenticationInformation.addAnother.addButton",
              ),
              onSubmit: () => navigateToFidoRegister(user, false), // we navigate to the normal fido registration page because the user has not enough own authenticators
              resetButtonLabel: t("button.close"),
              onClose: onCloseModal,
              backgroundLocked: true,
              submitButtonId: "authenticationInformation-add-another-submit",
              cancelButtonId: "authenticationInformation-add-another-cancel",
            });
          }
        }
      }
    };

    initialLoad();
  }, []);

  useEffect(() => {
    if (activeRadioValueDisableFidoEnforceReason !== undefined) {
      showModalDisableFidoEnforcement();
    }
  }, [
    activeRadioValueDisableFidoEnforceReason,
    otherReasonDisableFidoEnforcement,
  ]);

  const renameFido = async (logicalKeyId: string, displayName: string) => {
    setModal(undefined);
    setIsLoading({ loadingType: "standard" });
    try {
      const body: FidoRenameRequest = {
        logicalKeyId: logicalKeyId,
        displayName: displayName,
      };
      const { status, error, payload } = await httpPost<string | ErrorResponse>(
        `${apiPath}/user/fido/displayname`,
        body,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(payload ?? "REQUEST_FAILED");
        return false;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }

    const res = await fetchFidoData();
    if (res) {
      updateFidoData(res);
    }
    return true;
  };

  const deleteFido = useDeleteFido(updateFidoData);

  const enforceFido = useFetchDisableAdvancedProtectionPage(updateFidoData, setIsInFidoMigrationGroupState, user.preferredUsername);

  const moreInformationContent = () => {
    return (
      <>
        <div className={"portal-furtherInformation-firstRow"}>
          <I18NParagraph i18n={"portal.furtherInformation.intro.1"} />
          <I18NLink
            i18n={"portal.furtherInformation.intro.2"}
            link={window.origin + "/userguide"}
            openInNewTab={true}
          />
          <I18NParagraph i18n={"portal.furtherInformation.intro.3"} />
          <I18NLink
            i18n={"portal.furtherInformation.intro.4"}
            link={
              "https://evarooms.merckgroup.com/Topic/EUS/en-us/projects/moving-beyond-passwords"
            }
            openInNewTab={true}
          />
          <I18NParagraph i18n={"portal.furtherInformation.intro.5"} />
        </div>
        <I18NParagraph i18n={"portal.furtherInformation.content"} />
      </>
    );
  };

  const disableFidoEnforcementReason = () => {
    return (
      <>
        <RadioButtonGroup className={"no-background"}>
          <I18NParagraph i18n={"disableFidoEnforcement.reason.headline"} />
          <StandardRadioButton
            className={"no-background"}
            label={t("disableFidoEnforcement.reason.globalProtect")}
            selected={
              activeRadioValueDisableFidoEnforceReason ===
              REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT
            }
            onClick={() => {
              setActiveRadioValueDisableFidoEnforceReason(
                REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT,
              );
              setOtherReasonDisableFidoEnforcement(undefined);
            }}
          />
          <StandardRadioButton
            className={"no-background"}
            label={t("disableFidoEnforcement.reason.newIntuneDevice")}
            selected={
              activeRadioValueDisableFidoEnforceReason ===
              REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE
            }
            onClick={() => {
              setActiveRadioValueDisableFidoEnforceReason(
                REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE,
              );
              setOtherReasonDisableFidoEnforcement(undefined);
            }}
          />
          <TextAreaRadioButton
            className={"no-background"}
            label={t("disableFidoEnforcement.reason.other")}
            selected={
              activeRadioValueDisableFidoEnforceReason ===
              REASON_DISABLE_FIDO_ENFORCE.OTHER
            }
            maxLength={200}
            onClick={() => {
              setActiveRadioValueDisableFidoEnforceReason(
                REASON_DISABLE_FIDO_ENFORCE.OTHER,
              );
            }}
            onTextChange={(prevOtherReason: string) =>
              setOtherReasonDisableFidoEnforcement(prevOtherReason)
            }
          />
        </RadioButtonGroup>
      </>
    );
  };

  const welcomeImg = () => {
    return (
      <div className="header-svg">
        <div className="center-svg cp">
          <ReactSVG
            className="Merck-Regular"
            src={imageHero.toString()}
            beforeInjection={(svg) => {
              if (
                svg.getElementById("successStep1FirstRow") &&
                svg.getElementById("successStep1SecondRow") &&
                svg.getElementById("successStep1ThirdRow")
              ) {
                svg.getElementById("successStep1FirstRow").textContent =
                  t("svg.welcome.1");
                svg.getElementById("successStep1SecondRow").textContent =
                  t("svg.welcome.2");
                svg.getElementById("successStep1ThirdRow").textContent =
                  t("svg.welcome.3");
              }
            }}
          />
        </div>
      </div>
    );
  };

  const isFidoUsable = () => {
    return (
      fidoData.fidoGroupProps?.isInFidoEnabledGroup &&
      fidoData.ownAuthenticators.length >= 1
    );
  };

  const isFidoEnforced = () => {
    return (
      fidoData.fidoGroupProps?.isInFidoEnabledGroup &&
      !fidoData.fidoGroupProps.isInFidoMigrationGroup
    );
  };

  const isInEnableAdvancedProtectionExclusionGroup = () => {
    return (
      fidoData.fidoGroupProps?.isInEnableAdvancedProtectionExclusionGroup ??
      false
    );
  };

  const getUsageStatusDescription = () => {
    if (!fidoData || fidoData.ownAuthenticators.length < 2) {
      // there are less than 2 authenticators, so we can't check if 2 are used
      return t("portal.authenticationStatus.stepUsage.description.unfulfilled");
    } else if (!fidoData.signInRegularly) {
      // there are 2 or more authenticators, but only one is used
      return t("portal.authenticationStatus.stepUsage.description.negative", {
        authenticatorName: getNotRecentlyUsedAuthenticatorName(),
      });
    } else {
      // there are 2 or more authenticators and at least 2 are used
      return t("portal.authenticationStatus.stepUsage.description.positive", {
        authenticatorsInUse: getTwoUsedAuthenticatorName(),
      });
    }
  };

  const isDateValid = (date: string): boolean => {
    const dateObj = Date.parse(date);

    return !(date === null || dateObj < oldestAllowedDate.getTime());
  };

  const getNotRecentlyUsedAuthenticatorName = (): string => {
    // check if entry exists where lastUsedDate and creationDate are both older than 6 months
    const foundName = fidoData.ownAuthenticators.find((v) => !v.recentlyUsed);
    return foundName?.name || "No authenticator found";
  };

  const getTwoUsedAuthenticatorName = () => {
    const entriesUsedRecently = fidoData.ownAuthenticators.filter(
      (v) => v.recentlyUsed,
    );
    const foundEntriesNames = entriesUsedRecently.map((v) => v.name);

    if (foundEntriesNames && foundEntriesNames.length > 1) {
      return foundEntriesNames[0] + " & " + foundEntriesNames[1];
    } else if (foundEntriesNames.length === 1) {
      return foundEntriesNames[0];
    } else {
      return "No authenticator found";
    }
  };

  const getFidoEnforcementReason = (): string => {
    switch (activeRadioValueDisableFidoEnforceReason) {
      case REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT:
        return REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT.toString();
      case REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE:
        return REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE.toString();
      case REASON_DISABLE_FIDO_ENFORCE.OTHER:
        return otherReasonDisableFidoEnforcement ?? "";
    }
    return "None";
  };

  const showModalDisableFidoEnforcement = () => {
    setModal({
      type: "warning",
      onSubmit: () => {
        // Continue anyway we deactivate FIDO enforcement for the user
        enforceFido("disable", getFidoEnforcementReason());
        setModal(undefined);
      },
      onClose: () => {
        setModal(undefined);
        setOtherReasonDisableFidoEnforcement(undefined);
        setActiveRadioValueDisableFidoEnforceReason(undefined);
      },

      title: t("portal.warning.fidoEnforcePasswordMayExpired.title"),
      children: (
        <>
          <I18NParagraph
            i18n={"portal.warning.fidoEnforcePasswordMayExpired.subtitle"}
          />
          {disableFidoEnforcementReason()}
        </>
      ),
      submitButtonLabel: t(
        "portal.warning.fidoEnforcePasswordMayExpired.button.continue",
      ),
      submitButtonDisabled: !(
        (activeRadioValueDisableFidoEnforceReason ===
          REASON_DISABLE_FIDO_ENFORCE.OTHER &&
          (otherReasonDisableFidoEnforcement ?? "").trim().length > 0) ||
        activeRadioValueDisableFidoEnforceReason ===
          REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT ||
        activeRadioValueDisableFidoEnforceReason ===
          REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE
      ),
    });
  };

  const handleSetFidoEnforced = (value: boolean) => {
    const negateValue = !value;
    if (negateValue && fidoData.ownAuthenticators.length === 0) {
      // wants to be enforced but no fido devices are registered -> We warn the user
      setModal({
        type: "info",
        onSubmit: () => {
          // we enforce the user to use FIDO
          enforceFido("disable", "None");
          setModal(undefined);
        },
        onClose: () => {
          setModal(undefined);
        },
        title: t("portal.warning.fidoEnforceNoAuth.title"),
        children: (
          <I18NParagraph i18n={"portal.warning.fidoEnforceNoAuth.subtitle"} />
        ),
        submitButtonLabel: t(
          "portal.warning.fidoEnforceNoAuth.button.continue",
        ),
        resetButtonLabel: t("portal.warning.fidoEnforceNoAuth.button.cancel"),
        backgroundLocked: true,
      });
    } else if (!negateValue) {
      enforceFido("enable", "None");
    } else {
      // user wants to disable fido enforcement
      showModalDisableFidoEnforcement();
    }
  };

  const navigate = useNavigate();
  const handlePasskeysClick = () => navigate("/cp/passkeys");
  const handleManageAccountsClick = () => navigate("/cp/accounts");
  const handleRecoveryInitiationClick = () => {
    if (user.isPersonalAccount) {
      window.location.assign("/initiaterecovery?upnToAuthenticate=" +  decodeURIComponent(user.preferredUsername));
    } else {
      window.location.assign("/initiaterecovery");
    }
  };
  const handleRecoveryCompletionClick = () => {
    if (user.isPersonalAccount) {
      window.location.assign("/recovery?upnToAuthenticate="+ decodeURIComponent(user.preferredUsername));
    } else {
      window.location.assign("/recovery");
    }
  };
  const handleAdvancedProtectionClick = () => navigate('/cp/advanced-protection');

  const handleLessSecureSignInsClick = () => navigate('/cp/less-secure-sign-ins');

  const userPhoneNumberE164number = user.phoneNumbers[0]?.e164number || "";

  const hasNoSecurityIssue = !isInFidoMigrationGroupState && // not in migration
      fidoData.signInRegularly && // fido used regular
      fidoData.fidoGroupProps?.isInitialPasswordSet && // initial pwd
      isLessSecureSignIn(lessSecureSignIns) !== true; // No less secure active

  return (
    <div className={"portal"}>
      <div className="portal-main-wrapper">
        <div className="portal-main-content">
          <div className="portal-column">

            {initialLoad && (
              <>
                <Redesign>
                  <New>
                    {fidoData && !fidoData.fastSignInReady && (
                        <InfoBox
                            type="info"
                            title={t("portal.securityCheckup.title")}
                        >
                          <p>
                            {t("portal.authenticationStatus.stepAuthentication.description")}
                          </p>
                          <Button
                              id="authenticationInformation-add-another-submit"
                              mode="yellow"
                              label="Create passkey"
                              onClick={() => navigateToFidoRegister(user, false)} // we navigate to the normal fido registration page because the user has not enough own authenticators
                          />
                        </InfoBox>
                    )}
                  </New>
                </Redesign>
                <div className="portal-column-item">
                  <Redesign>
                    <New>
                      <I18NHeading i18n={"portal.welcome"} type={"h3"} />
                      <Avatar
                        name={user.name}
                        description={user.mail}
                        image={
                          user.picture ? `${apiPath}/user/picture` : undefined
                        }
                        mobileNumber={userPhoneNumberE164number}
                        hasManagedAccounts={user.hasOwnedAccounts}
                        onManageAccounts={() => handleManageAccountsClick()}
                      />
                    </New>
                    <Old>
                      <I18NHeading
                        i18n={"portal.headline"}
                        options={{ fullUsername: user.name }}
                        type={"h3"}
                      />
                      <I18NParagraph i18n={t("portal.explanation")} />
                    </Old>
                  </Redesign>
                </div>

                <div className="portal-column-item">
                  <Redesign>
                    <Old>
                      <I18NHeading
                        i18n={"portal.authenticationStatus.headline"}
                        type={"h3"}
                        id="portalInfoPageHeading"
                      />
                    </Old>
                    <New>
                      <Spacing flexDirection="column" gap="24">
                        <CardGroupHeader
                          status={
                            hasNoSecurityIssue  ? "success" : "warning"
                          }
                          description={
                            hasNoSecurityIssue ? t("portal.securityCheckup.success")  : t("portal.securityCheckup.warning")
                          }
                          title={t("portal.securityCheckup.title")}
                        />
                        <CardGroup>
                          {isInFidoMigrationGroupState !== undefined && (
                            <CardGroupItem
                              id="hasFidoEnforcedStatus"
                              label={t(
                                "portal.securityCheckup.advancedProtection.headline",
                              )}
                              description={isInFidoMigrationGroupState
                                ? t(
                                    "portal.securityCheckup.advancedProtection.warning",
                                  )
                                : t(
                                    "portal.securityCheckup.advancedProtection.success",
                                  )}
                              onClick={handleAdvancedProtectionClick}
                              icon="shield-plus"
                              badge={
                                <Badge
                                  label={
                                    isInFidoMigrationGroupState ? "Off" : "On"
                                  }
                                  status={
                                    isInFidoMigrationGroupState
                                      ? "warning"
                                      : "success"
                                  }
                                />
                              }
                            />
                          )}
                          <CardGroupItem label={t("portal.securityCheckup.lessSecureSignIns.headline")}
                             description={isLessSecureSignIn(lessSecureSignIns) === true ? t("portal.securityCheckup.lessSecureSignIns.warning") : t("portal.securityCheckup.lessSecureSignIns.success")}
                             icon="shield-plus"
                             onClick={handleLessSecureSignInsClick}
                             disabled={isLessSecureSignIn(lessSecureSignIns) === "DISABLED"}
                             badge={
                               <Badge label={
                                 isLessSecureSignIn(lessSecureSignIns) === true ?  "On" : "Off"
                               } status={
                                 isLessSecureSignIn(lessSecureSignIns) === true ? "warning" : "success"
                               }/>
                             }
                          />
                          <CardGroupItem
                            label={t("portal.securityCheckup.password")}
                            description={
                              fidoData.fidoGroupProps?.isInitialPasswordSet
                                ? t("portal.securityCheckup.passwordChanged")
                                : t("portal.securityCheckup.changePassword")
                            }
                            icon="passcode"
                            badge={
                              !fidoData.fidoGroupProps
                                ?.isInitialPasswordSet && (
                                <Badge label="Initial" status="warning" />
                              )
                            }
                            onClick={() => navigateToPasswordReset(user)}
                          />
                          <CardGroupItem
                            id="hasFidoInActiveUsageStatus"
                            badge={
                              <>
                                <Badge
                                  label={
                                    fidoData.ownAuthenticators.length === 1
                                      ? "1 Key"
                                      : fidoData.ownAuthenticators.length > 1
                                      ? fidoData.ownAuthenticators.length +
                                        " Keys"
                                      : "No Keys"
                                  }
                                  status={
                                    fidoData.ownAuthenticators.length >= 2
                                      ? "success"
                                      : "warning"
                                  }
                                />
                                <Badge
                                  label={t("portal.securityCheckup.signedInRegularly")}
                                  status={
                                    fidoData.signInRegularly
                                      ? "success"
                                      : "warning"
                                  }
                                />
                              </>
                            }
                            onClick={handlePasskeysClick}
                            label={t("portal.securityCheckup.passkeys")}
                            description={getUsageStatusDescription()}
                            icon="passkey"
                          />
                          <CardGroupItem
                            label={t("portal.securityCheckup.mobileNumber")}
                            description={
                              userPhoneNumberE164number ? userPhoneNumberE164number : t(
                                    "portal.securityCheckup.mobileNumberDescription",
                                  )
                            }
                            onClick={() =>
                              navigateToAzureMfaRegistration(user, user.hasOwnedAccounts)
                            }
                            icon="phone-01"
                          />
                        </CardGroup>
                      </Spacing>
                    </New>
                    <Old>
                      <AuthenticationStatus
                        id="hasFidoRegisteredStatus"
                        headline={t(
                          "portal.authenticationStatus.stepAuthentication.headline",
                        )}
                        description={t(
                          "portal.authenticationStatus.stepAuthentication.description",
                        )}
                        done={fidoData.fastSignInReady}
                        action={{
                          type: "button",
                          mode: "yellow",
                          label: t(
                            "portal.authenticationStatus.stepAuthentication.buttonLabel",
                          ),
                          onClick: () => navigateToFidoRegister(user,false), // we navigate to the normal fido registration page because the user has not enough own authenticators
                        }}
                      />
                      <AuthenticationStatus
                        id="hasFidoInActiveUsageStatus"
                        headline={t(
                          "portal.authenticationStatus.stepUsage.headline",
                        )}
                        description={getUsageStatusDescription()}
                        done={fidoData.signInRegularly}
                        action={{ type: "none" }}
                      />
                    </Old>
                  </Redesign>
                  {isInFidoMigrationGroupState !== undefined && (
                    <Redesign>
                      <Old>
                        <AuthenticationStatus
                          id="hasFidoEnforcedStatus"
                          headline={t(
                            "portal.authenticationStatus.stepEnforcement.headline",
                          )}
                          description={
                            isInEnableAdvancedProtectionExclusionGroup() &&
                            !isFidoEnforced()
                              ? t(
                                  "portal.authenticationStatus.stepEnforcement.descriptionExclusionGroup",
                                )
                              : t(
                                  "portal.authenticationStatus.stepEnforcement.description",
                                )
                          }
                          done={!isInFidoMigrationGroupState}
                          action={{
                            type: "toggle",
                            toggleState: !isInFidoMigrationGroupState,
                            setToggleState: handleSetFidoEnforced,
                            disabled:
                              !(isFidoUsable() || isFidoEnforced()) ||
                              (isInEnableAdvancedProtectionExclusionGroup() &&
                                !isFidoEnforced()),
                          }}
                        />
                      </Old>
                    </Redesign>
                  )}
                </div>

                <Redesign>
                  <New>
                    <div className="portal-column-item">
                      <Spacing flexDirection="column" gap="24">
                        <CardGroupHeader
                          status="info"
                          description={t("progress.recoveryDescription")}
                          title={t("progress.recoveryHeadline")}
                        />
                        <CardGroup>
                          <CardGroupItem
                            label={t("portal.recovery.initiateRecovery")}
                            description={t("portal.recovery.initiateRecoveryDescription")}
                            onClick={() =>
                              handleRecoveryInitiationClick()
                            }
                          />
                          <CardGroupItem
                            label={t("portal.recovery.recovery")}
                            description={t("portal.recovery.recoveryDescription")}
                            onClick={() =>
                              handleRecoveryCompletionClick()
                            }
                          />
                        </CardGroup>
                      </Spacing>
                    </div>
                  </New>
                </Redesign>
                <Redesign>
                  <Old>
                    <div className="portal-column-item">
                      <I18NHeading
                          i18n={"portal.fidoCredentials.account.overview"}
                          type={"h3"}
                      />
                      <div className="portal-user-overview">
                        {!user.hasOwnedAccounts ? (
                            <UserCardPersonal
                                name={user.name}
                                personalAccount={user.isPersonalAccount}
                                preferredUsername={user.preferredUsername}
                                actionRegisterPersonal={() =>
                                    navigateToFidoRegister(user,false)
                                }
                                actionTestPersonal={() =>
                                    navigateToFidoDemo(user.preferredUsername)
                                }
                                actionResetPwPersonal={() =>
                                    navigateToPasswordReset(user, false)
                                }
                                actionRegisterAzureMfa={() =>
                                    navigateToAzureMfaRegistration(user, false)
                                }
                            />
                        ) : (
                            <UserCardManaged
                                name={user.name}
                                actionRegisterPersonal={() =>
                                    navigateToFidoRegister(user, false)
                                }
                                actionTestPersonal={() =>
                                    navigateToFidoDemo(user.preferredUsername)
                                }
                                actionResetPwPersonal={() =>
                                    navigateToPasswordReset(user,false)
                                }
                                actionRegisterManaged={() =>
                                    navigateToFidoRegister(user, true)
                                }
                                actionLogoutManaged={() => switchAccount()}
                                actionResetPwManaged={() =>
                                    navigateToPasswordReset(user,true)
                                }
                                actionRegisterAzureMfaPersonal={() =>
                                    navigateToAzureMfaRegistration(user, false)
                                }
                                actionRegisterAzureMfaManaged={() =>
                                    navigateToAzureMfaRegistration(user, true)
                                }
                            />
                        )}
                      </div>
                    </div>
                    <div className="portal-column-item">
                      <Spacing flexDirection="column" gap="20">
                        <I18NHeading i18n={"portal.recovery.header"} type={"h3"} />
                        <CardGroup>
                          <CardGroupItem
                              disabled={!user.hasOwnedAccounts}
                              label={t("portal.recovery.initiateRecovery")}
                              description={t("portal.recovery.initiateRecoveryDescription")}
                              onClick={() =>
                                  handleRecoveryInitiationClick()
                              }
                          />
                        </CardGroup>
                        <CardGroup>
                          <CardGroupItem
                              label={t("portal.recovery.recovery")}
                              description={t("portal.recovery.recoveryDescription")}
                              onClick={() =>
                                  handleRecoveryCompletionClick()
                              }
                          />
                        </CardGroup>
                      </Spacing>
                    </div>
                    <div className="portal-column-item">
                      <I18NHeading
                          i18n={"portal.yourAuthenticators"}
                          type={"h3"}
                      />
                      {fidoData.authenticatorListEntryPropsByUpns
                      .filter(
                          (v) =>
                              v.authenticators.length > 0 ||
                              v.upn === user.preferredUsername,
                      )
                      .map((v) => (
                          <div key={v.upn}>
                            <Heading type={"h3"} text={v.upn}/>
                            <AuthenticatorList
                                setModal={setModal}
                                onCloseModal={onCloseModal}
                                listID={"fidoAuthenticatorsEntry"}
                                authenticators={v.authenticators}
                                registerNewAuthAction={() =>
                                    navigateToFidoRegister(user, v.upn !== user.preferredUsername)
                                }
                                onSwitchAccount={
                                  v.upn !== user.preferredUsername
                                      ? () => switchAccount(v.upn)
                                      : undefined
                                }
                            />
                            <br/>
                            <br/>
                          </div>
                      ))}
                    </div>
                    <div className="portal-column-item">{welcomeImg()}</div>
                    <div className="portal-column-item">
                      <Expander header={t("portal.furtherInformation.label")}>
                        {moreInformationContent()}
                      </Expander>
                    </div>
                  </Old>
                </Redesign>
              </>
              )}
          </div>
        </div>
      </div>

      <div className="mainWrapper">
        {modal && (
            <Modal
                type={modal.type}
                onSubmit={modal.onSubmit}
                title={modal.title}
                submitButtonLabel={modal.submitButtonLabel}
                resetButtonLabel={modal.resetButtonLabel}
                onClose={modal.onClose}
                backgroundLocked={true}
                submitButtonId={modal.submitButtonId}
                cancelButtonId={modal.cancelButtonId}
                onMagicBtn={modal.onMagicBtn}
                magicButtonId={modal.magicButtonId}
                magicButtonLabel={modal.magicButtonLabel}
                submitButtonDisabled={modal.submitButtonDisabled}
            >{modal.children}
            </Modal>
        )}
      </div>
    </div>
  );
};
