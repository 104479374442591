import React, {createContext, FunctionComponent, ReactNode, useContext, useEffect, useState} from 'react';
import {ErrorResponse, UserInfo} from "../interfaces/types";
import {useGlobalState} from "../GlobalState";
import {client} from "../utils/fetch-client";
import {apiPath} from "../utils/apiUrl";

interface UserContext {
  user: UserInfo | undefined;
  setUser: React.Dispatch<UserInfo>;
  signedIn: boolean;
  loading: boolean;
}

const defaultUserContext: UserContext = {user: undefined, setUser: (u: UserInfo) => u, signedIn: false, loading: false};

const UserContext = createContext<UserContext>(defaultUserContext);

export const UserContextProvider: FunctionComponent<{ children: ReactNode }> = ({children}) => {
  const [, setGlobalError] = useGlobalState("error");
  const [user, setUser] = useState<UserInfo>();
  const [loading, setLoading] = useState<boolean>(true);
  const [signedIn, setSignedIn] = useState(false);

  async function fetchUserData() : Promise<UserInfo|undefined> {

    const {status, error, payload} = await client.query<UserInfo>({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      endpoint: `${apiPath}/user`,
      responseType: "json",
    });

    if (status === 401) {
     // signInFunction();
      setSignedIn(false);
      return;
    }

    // Check for any error in response
    if (error) {
      setGlobalError((payload as ErrorResponse | undefined) ?? "REQUEST_FAILED");
      return;
    }

    if (status == 200 && payload) {
      return payload;
    }
  }

  useEffect(() => {
    fetchUserData()
    .then(setUser)
    .then(() => setSignedIn(true))
    .finally(() => setLoading(false));
  }, []);

  return (<UserContext.Provider value={{user, setUser, signedIn, loading}}>
    {children}
  </UserContext.Provider>);
};

/*
function useFetchUser(): UserInfo | undefined {
  const [, setGlobalError] = useGlobalState("error");
  // const context = useContext(userContext);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {

    const fetchUserData = async () => {

      const {status, error, payload} = await client.query<UserInfo>({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        endpoint: `${apiPath}/user`,
        responseType: "json",
      });

      if (status === 401) {
        signInFunction();
        return;
      }

      // Check for any error in response
      if (error) {
        setGlobalError((payload as ErrorResponse | undefined) ?? "REQUEST_FAILED");
        return;
      }

      if (status == 200 && payload) {
        setUser(payload);
      }
    };

    if (!user) {
      fetchUserData();
    }

  }, []);

  return user;
}
*/

// Hook zum einfachen Zugriff auf den Context
export function useUser() {
  return useContext(UserContext);
}