import React from 'react';
import './userCardPersonal.scss';
import {UserCardPersonalProps} from "../../../../shared/interfaces";
import {Icon} from "../Icon/Icon";
import {Paragraph} from "../Paragraph/Paragraph";
import {I18NButton} from "../I18NButton/I18NButton";
import {I18NHeading} from "../I18NHeading/I18NHeading";

export const UserCardPersonal = ({
                                     name,
                                     personalAccount,
                                     preferredUsername,
                                     actionRegisterPersonal,
                                     actionTestPersonal,
                                     actionResetPwPersonal,
                                     actionRegisterAzureMfa
                                 }: UserCardPersonalProps) => {

    return (
        <div className="personal-usercard-wrapper">
            <div className={"portalUserCard"}>
                <div className={"personal-usercard-content"}>
                    <div className={"personal-usercard-userinfo-wrapper"}>
                        {personalAccount &&
                            <I18NHeading i18n={"portal.fidoCredentials.account.personal.headline"} type={"h3"}/>}
                        {!personalAccount &&
                            <I18NHeading i18n={"portal.fidoCredentials.account.current.headline"} type={"h3"}/>}
                        <div className={"personal-usercard-userinfo"}>
                            <Icon icon={"user"} sizeInPx={50}/>
                            <Paragraph text={name} className={"portalUserCard-username"}/>
                            <Paragraph text={preferredUsername} className={"portalUserCard-username"}/>
                        </div>
                    </div>
                    <div className={"personal-usercard-buttongroup"}>
                        <I18NButton mode="yellow" border={true} i18n={"button.registerBiometric"}
                                    onClick={actionRegisterPersonal}/>
                        <I18NButton mode="white" border={true} id={"portalTestDevice"} i18n={"button.testBiometric"}
                                     onClick={actionTestPersonal}/>
                        <I18NButton mode="white" border={true} i18n={"button.resetPassword"}
                                    onClick={actionResetPwPersonal}/>
                        <I18NButton mode="white" border={true} i18n={"button.registerAzureMfa"}
                                    onClick={actionRegisterAzureMfa}/>
                    </div>
                </div>
            </div>
        </div>
    );

};
