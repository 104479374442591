import React from 'react';
import "./infoBox.scss";
import {InfoBoxProps} from "../../../../shared/interfaces";
import {Button} from "../Button/Button";
import {useTranslation} from "react-i18next";
import {Heading} from "../Heading/Heading";
import { UntitledColorizeIcon, UntitledIcon } from '../icons';

/**
 * Primary UI component for user interaction
 */
export const InfoBox : React.FC<InfoBoxProps> = (props) => {

  const {t} = useTranslation();

  if(!props.type){
    props.type = "error";
  }

  const submitOnClick = () => {
    if(props.onSubmit){
      props.onSubmit();
    }
  };

  const resetOnClick = () => {
    if(props.onClose){
      props.onClose();
    }
  };

  let buttons = (<></>);


  switch (props.type) {
    case "success":
        buttons = (<>{props.onSubmit && <Button label={t('button.next')} mode="success" onClick={submitOnClick}/>}</>);
      break;
    case "error":
        buttons = (<>{props.onSubmit && <Button label={t('button.next')} mode="error" onClick={submitOnClick}/>}</>);
      break;
    case "warning":
      buttons = (
          <>
            {props.onClose && <Button label={t('button.cancel')} mode="white" border={true} onClick={resetOnClick}/>}
            {props.onSubmit && <Button label={t('button.yes')} mode="warning" className="button-lm" onClick={submitOnClick}/>}
          </>
      );
      break;
  }

  return (
        <div id={props.id} aria-label={props.id} className={["storybook-info-box--wrapper", props.className].join(" ")}>
          <div className={`storybook-box storybook-${props.type}-box`}>
            <div className="storybook-info-box--content">
            <UntitledIcon name={`badge-${props.type}`} onClick={resetOnClick} />
              {/* <Icon className="storybook-info-box--icon" icon={props.type ? props.type : "info"}/> */}
              <div className="storybook-info-box--text">
                {props.title && <Heading text={props.title} type={"h4"} className="storybook-headline" />}
                {props.subTitle && <p className="storybook-subtitle">{props.subTitle}</p>}
                {!props.subTitle && props.children}
              </div>
              {props.type !== "success" && props.onClose &&
              <UntitledColorizeIcon name="cross" onClick={resetOnClick}
                          className={`storybook-close storybook-close-${props.type}`}/>
              }
            </div>

            <div className="storybook-info-box--buttons">
              {buttons}
            </div>
          </div>
        </div>
  );
};
