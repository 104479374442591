import {createGlobalState} from "react-hooks-global-state";

const {useGlobalState} = createGlobalState<{ activeTheme: "old" | "new" }>({
  activeTheme: "new"
});

export function useActiveTheme() {
  const [activeTheme, setActiveTheme] = useGlobalState("activeTheme");

  return {
    activeTheme,
    isOldTheme: activeTheme === "old",
    isNewTheme: activeTheme === "new",
    setTheme: (isOld?: boolean) => {
      const theme = isOld ? "old" : "new";
      localStorage.setItem("activeTheme2", theme);
      setActiveTheme(theme);
    },
    setActiveThemeFromStorage: () => {
      const activeThemeFromLocalStorage = localStorage.getItem("activeTheme2");
      if (activeThemeFromLocalStorage && activeThemeFromLocalStorage !== activeTheme) {
        setActiveTheme(activeThemeFromLocalStorage === "old" ? "old" : "new");
      }
    }
  };
}