import { clsx } from "clsx";
import { Button } from "../Button/Button";
import { UntitledColorizeIcon } from "../icons";
import { Spacing } from "../Spacing";
import { AvatarProps } from "./avatar.interface";
import "./avatar.scss";
import React, {FunctionComponent, useEffect, useState} from "react";

const ImageWithFallBack:  FunctionComponent<{ src: string | undefined, alt:string }> = ({ src, alt }) => {
  const [imageAvailable, setImageAvailable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function checkImage() {

      if (src) {
        try {
          const response = await fetch(src, {method: "HEAD"});
          if (response.ok) {
            setImageAvailable(true);
          } else {
            setImageAvailable(false);
          }
        } catch {
          setImageAvailable(false);
        } finally {
          setLoading(false);
        }
      } else {
        setImageAvailable(false);
        setLoading(false);
      }
    }

    checkImage();
  }, [src, alt]);


  if (loading) {
    return (<></>);
  }

  if (imageAvailable) {
    return <img src={src} alt={alt} className="avatar-image"/>;
  }

  return <span className="initial-avatar">{getInitials(alt)}</span>;

};

function getInitials(name:string): string  {
  return name
  .replace(/[^a-zA-Z\s]/g, '')
  .split(/\s+/)
  .map(word => word.charAt(0).toUpperCase())
  .join('')
  .slice(0, 3);
}

export const Avatar: React.FC<AvatarProps> = ({
                                                name,
                                                description,
                                                image,
                                                hasManagedAccounts = false,
                                                onAddMobileNumber,
                                                onManageAccounts,
  mobileNumber,
  className,
  onlyImage = false,
  noMobileNumber = false,
  noManageAccounts = false,
  noCard = false,
  ...props
}) => {

  return (
    <div
      className={clsx("avatar-card", { "no-card": noCard }, className)}
      tabIndex={0}
      data-testid="avatar-card"
      {...props}
    >
      <Spacing flexDirection="column" gap="12">
        <Spacing justifyContent="space-between">
          <ImageWithFallBack src={image} alt={name} />
          {!onlyImage && !noManageAccounts && (
            <Button
              mode="link"
              disabled={!hasManagedAccounts}
              style={{
                opacity: hasManagedAccounts ? 1 : 0.5,
                color: hasManagedAccounts
                  ? "var(--button-outline-label-color)"
                  : "var(--text-color-muted)",
              }}
              className="manage-accounts"
              onClick={onManageAccounts}
              label="Manage accounts"
            ></Button>
          )}
        </Spacing>
        {!onlyImage && (
          <Spacing flexDirection="column">
          <span className="name">{name}</span>
          <span className="description">{description}</span>
          {!noMobileNumber &&
            (mobileNumber ? (
              <Spacing fullWidthChildren={false} gap="16" alignItems="center">
                <span className="mobile-number">{mobileNumber}</span>
              </Spacing>
            ) :  (onAddMobileNumber &&
              <Button
                icon={<UntitledColorizeIcon name="phone-01" />}
                mode="link"
                className="add-mobile-number"
                onClick={onAddMobileNumber}
                label="Add mobile number"
              ></Button>
            ))}
        </Spacing>
        )}
      </Spacing>
    </div>
  );
};
