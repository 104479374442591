export const UntitledIcons = {
  "badge-error": () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
        fill="#E61E50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1261 13.9733C10.2003 13.9733 9.44986 14.7237 9.44986 15.6495C9.44986 16.5752 10.2003 17.3257 11.1261 17.3257C12.0518 17.3257 12.8022 16.5752 12.8022 15.6495C12.8022 14.7237 12.0518 13.9733 11.1261 13.9733ZM12.2804 4.81717C11.9325 4.46928 11.4521 4.29534 10.8392 4.29534L9.67125 4.29534C9.43932 4.29534 9.32336 4.39473 9.32336 4.59353L9.32336 10.3337C9.32336 10.9466 9.4973 11.4271 9.84519 11.7749C10.1931 12.1228 10.6735 12.2968 11.2864 12.2968L12.4544 12.2968C12.6863 12.2968 12.8022 12.1974 12.8022 11.9986L12.8022 6.25842C12.8022 5.64547 12.6283 5.16506 12.2804 4.81717Z"
        fill="white"
      />
    </g>
  ),
  "badge-info": () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
        fill="#0F69AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9994 7.64772C11.9252 7.64772 12.6756 6.89727 12.6756 5.97153C12.6756 5.0458 11.9252 4.29534 10.9994 4.29534C10.0737 4.29534 9.32324 5.0458 9.32324 5.97153C9.32324 6.89727 10.0737 7.64772 10.9994 7.64772ZM9.84508 16.8038C10.193 17.1517 10.6734 17.3257 11.2863 17.3257H12.4542C12.6862 17.3257 12.8021 17.2263 12.8021 17.0275V11.2873C12.8021 10.6744 12.6282 10.1939 12.2803 9.84605C11.9324 9.49816 11.452 9.32422 10.839 9.32422H9.67113C9.4392 9.32422 9.32324 9.42362 9.32324 9.62241V15.3626C9.32324 15.9755 9.49718 16.4559 9.84508 16.8038Z"
        fill="white"
      />
    </g>
  ),
  "badge-success": () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#01884C"
        d="M11,22c6.1,0,11-4.9,11-11c0-6.1-4.9-11-11-11C4.9,0,0,4.9,0,11C0,17.1,4.9,22,11,22z"
      />
      <path
        fill="none"
        stroke="#ffffff"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.5,8.3l-5.9,5.4l-3.1-3"
      />
    </g>
  ),
  "badge-warning": () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
        fill="#FFC832"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9997 7.64772C11.9254 7.64772 12.6759 6.89727 12.6759 5.97153C12.6759 5.0458 11.9254 4.29534 10.9997 4.29534C10.0739 4.29534 9.32349 5.0458 9.32349 5.97153C9.32349 6.89727 10.0739 7.64772 10.9997 7.64772ZM9.84532 16.8038C10.1932 17.1517 10.6736 17.3257 11.2866 17.3257H12.4545C12.6864 17.3257 12.8024 17.2263 12.8024 17.0275V11.2873C12.8024 10.6744 12.6284 10.1939 12.2805 9.84605C11.9326 9.49816 11.4522 9.32422 10.8393 9.32422H9.67138C9.43945 9.32422 9.32349 9.42362 9.32349 9.62241V15.3626C9.32349 15.9755 9.49743 16.4559 9.84532 16.8038Z"
        fill="#0F1A2E"
      />
    </g>
  ),
  "shield-success": () => (
    <g>
      <path
        fill="#01884C"
        stroke="#01884C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.7,16c0,6.5-7.1,11.3-9.7,12.8c-0.3,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.7-0.3
	c-2.6-1.5-9.7-6.3-9.7-12.8V9.6c0-1.1,0-1.6,0.2-2.1c0.2-0.4,0.4-0.8,0.7-1.1C6.6,6.2,7.1,6,8.1,5.6l7.1-2.7
	c0.3-0.1,0.4-0.2,0.6-0.2c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.6,0.2l7.1,2.7c1,0.4,1.5,0.6,1.9,0.9c0.3,0.3,0.6,0.6,0.7,1.1
	c0.2,0.5,0.2,1,0.2,2.1V16z"
      />
      <path
        fill="none"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12,16.3l2.7,2.7l6-6"
      />
    </g>
  ),
  "shield-warning": () => (
    <g>
      <path
        fill="#FFC832"
        stroke="#FFC832"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.7,16c0,6.5-7.1,11.3-9.7,12.8c-0.3,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.7-0.3
	c-2.6-1.5-9.7-6.3-9.7-12.8V9.6c0-1.1,0-1.6,0.2-2.1c0.2-0.4,0.4-0.8,0.7-1.1C6.6,6.2,7.1,6,8.1,5.6l7.1-2.7
	c0.3-0.1,0.4-0.2,0.6-0.2c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.6,0.2l7.1,2.7c1,0.4,1.5,0.6,1.9,0.9c0.3,0.3,0.6,0.6,0.7,1.1
	c0.2,0.5,0.2,1,0.2,2.1V16z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#0F1A2E"
        d="M15.8,12.7c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8S14,9.8,14,10.8C14,11.8,14.8,12.7,15.8,12.7z M14.6,22.6
	c0.4,0.4,0.9,0.6,1.6,0.6h1.3c0.3,0,0.4-0.1,0.4-0.3v-6.3c0-0.7-0.2-1.2-0.6-1.6c-0.4-0.4-0.9-0.6-1.6-0.6h-1.3
	c-0.3,0-0.4,0.1-0.4,0.3v6.3C14,21.7,14.2,22.3,14.6,22.6z"
      />
    </g>
  ),
  "star-01": () => (
    <path
      d="M11.2827 3.4533C11.5131 2.98637 11.6284 2.7529 11.7848 2.6783C11.9209 2.6134 12.0791 2.6134 12.2152 2.6783C12.3717 2.7529 12.4869 2.98637 12.7174 3.4533L14.9041 7.88327C14.9721 8.02112 15.0061 8.09005 15.0558 8.14356C15.0999 8.19095 15.1527 8.22934 15.2113 8.25661C15.2776 8.28741 15.3536 8.29853 15.5057 8.32076L20.397 9.03569C20.9121 9.11098 21.1696 9.14862 21.2888 9.27443C21.3925 9.38388 21.4412 9.53428 21.4215 9.68376C21.3988 9.85557 21.2124 10.0372 20.8395 10.4004L17.3014 13.8464C17.1912 13.9538 17.136 14.0075 17.1004 14.0715C17.0689 14.128 17.0487 14.1902 17.0409 14.2545C17.0321 14.3271 17.0451 14.403 17.0711 14.5547L17.906 19.4221C17.994 19.9355 18.038 20.1922 17.9553 20.3445C17.8833 20.477 17.7554 20.57 17.6071 20.5975C17.4366 20.6291 17.2061 20.5078 16.7451 20.2654L12.3724 17.9658C12.2361 17.8942 12.168 17.8583 12.0962 17.8443C12.0327 17.8318 11.9673 17.8318 11.9038 17.8443C11.832 17.8583 11.7639 17.8942 11.6277 17.9658L7.25492 20.2654C6.79392 20.5078 6.56341 20.6291 6.39297 20.5975C6.24468 20.57 6.11672 20.477 6.04474 20.3445C5.962 20.1922 6.00603 19.9355 6.09407 19.4221L6.92889 14.5547C6.95491 14.403 6.96793 14.3271 6.95912 14.2545C6.95132 14.1902 6.93111 14.128 6.89961 14.0715C6.86402 14.0075 6.80888 13.9538 6.69859 13.8464L3.16056 10.4004C2.78766 10.0372 2.60121 9.85557 2.57853 9.68376C2.55879 9.53428 2.60755 9.38388 2.71125 9.27443C2.83044 9.14862 3.08797 9.11098 3.60304 9.03569L8.49431 8.32076C8.64642 8.29853 8.72248 8.28741 8.78872 8.25661C8.84736 8.22934 8.90016 8.19095 8.94419 8.14356C8.99391 8.09005 9.02793 8.02112 9.09597 7.88327L11.2827 3.4533Z"
      fill="#ffffff"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "star-01-filled": () => (
    <path
      d="M11.2827 3.4533C11.5131 2.98637 11.6284 2.7529 11.7848 2.6783C11.9209 2.6134 12.0791 2.6134 12.2152 2.6783C12.3717 2.7529 12.4869 2.98637 12.7174 3.4533L14.9041 7.88327C14.9721 8.02112 15.0061 8.09005 15.0558 8.14356C15.0999 8.19095 15.1527 8.22934 15.2113 8.25661C15.2776 8.28741 15.3536 8.29853 15.5057 8.32076L20.397 9.03569C20.9121 9.11098 21.1696 9.14862 21.2888 9.27443C21.3925 9.38388 21.4412 9.53428 21.4215 9.68376C21.3988 9.85557 21.2124 10.0372 20.8395 10.4004L17.3014 13.8464C17.1912 13.9538 17.136 14.0075 17.1004 14.0715C17.0689 14.128 17.0487 14.1902 17.0409 14.2545C17.0321 14.3271 17.0451 14.403 17.0711 14.5547L17.906 19.4221C17.994 19.9355 18.038 20.1922 17.9553 20.3445C17.8833 20.477 17.7554 20.57 17.6071 20.5975C17.4366 20.6291 17.2061 20.5078 16.7451 20.2654L12.3724 17.9658C12.2361 17.8942 12.168 17.8583 12.0962 17.8443C12.0327 17.8318 11.9673 17.8318 11.9038 17.8443C11.832 17.8583 11.7639 17.8942 11.6277 17.9658L7.25492 20.2654C6.79392 20.5078 6.56341 20.6291 6.39297 20.5975C6.24468 20.57 6.11672 20.477 6.04474 20.3445C5.962 20.1922 6.00603 19.9355 6.09407 19.4221L6.92889 14.5547C6.95491 14.403 6.96793 14.3271 6.95912 14.2545C6.95132 14.1902 6.93111 14.128 6.89961 14.0715C6.86402 14.0075 6.80888 13.9538 6.69859 13.8464L3.16056 10.4004C2.78766 10.0372 2.60121 9.85557 2.57853 9.68376C2.55879 9.53428 2.60755 9.38388 2.71125 9.27443C2.83044 9.14862 3.08797 9.11098 3.60304 9.03569L8.49431 8.32076C8.64642 8.29853 8.72248 8.28741 8.78872 8.25661C8.84736 8.22934 8.90016 8.19095 8.94419 8.14356C8.99391 8.09005 9.02793 8.02112 9.09597 7.88327L11.2827 3.4533Z"
      fill="#FFC832"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  logo: () => (
    <>
      <g clipPath="url(#clip0_614_11457)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.7473 0.216309H27.36C27.2411 0.217407 27.1329 0.265187 27.0518 0.342075L22.2686 4.90481C20.63 6.46893 18.4249 7.3471 16.0003 7.3471C13.4729 7.3471 11.1862 6.3882 9.52923 4.70655C9.52923 4.70655 6.01098 1.34985 5.98071 1.31965C5.30549 0.681479 4.37619 0.240473 3.30634 0.216309H0.450324C0.201645 0.216309 0 0.421159 0 0.673791V13.4943C0 14.7596 1.00823 15.7839 2.25324 15.7839H3.12523H3.71449C3.96479 15.7839 4.16752 15.5774 4.16752 15.3236L4.1686 10.7615C4.1686 9.56915 5.12655 8.58554 6.28128 8.58554C8.43073 8.58554 9.97199 10.4561 11.3646 11.7055C13.0702 13.2361 14.3839 14.7107 16.0003 14.7107C17.6151 14.7107 18.9293 13.2361 20.6349 11.7055C22.028 10.4561 23.5693 8.58554 25.7176 8.58554C26.8664 8.58554 27.8195 9.55927 27.8309 10.7422V13.4943C27.8309 14.7596 28.8396 15.7817 30.0852 15.7817H31.0577C31.3118 15.7817 31.5475 15.7839 31.5475 15.7839C31.7973 15.7839 32 15.5774 32 15.3242C32 15.3176 32 2.61301 32 2.50592C32 1.24111 30.9907 0.216309 29.7473 0.216309Z"
          fill="#FFC832"
        />
      </g>
      <defs>
        <clipPath id="clip0_614_11457">
          <rect
            width="32"
            height="15.5676"
            fill="white"
            transform="translate(0 0.216309)"
          />
        </clipPath>
      </defs>
    </>
  ),
} as const;

export const UntitledColorizeIcons = {
  "edit-02": () => (
    <path
      d="M18 10L14 6M2.49997 21.5L5.88434 21.124C6.29783 21.078 6.50457 21.055 6.69782 20.9925C6.86926 20.937 7.03242 20.8586 7.18286 20.7594C7.35242 20.6475 7.49951 20.5005 7.7937 20.2063L21 7C22.1046 5.89543 22.1046 4.10457 21 3C19.8954 1.89543 18.1046 1.89543 17 3L3.7937 16.2063C3.49952 16.5005 3.35242 16.6475 3.24061 16.8171C3.1414 16.9676 3.06298 17.1307 3.00748 17.3022C2.94493 17.4954 2.92195 17.7021 2.87601 18.1156L2.49997 21.5Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "shield-plus": () => (
    <g>
      <path
        fill="none"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20,12c0,4.9-5.4,8.5-7.3,9.6c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3-0.1-0.5-0.2
      C9.4,20.5,4,16.9,4,12V7.2C4,6.4,4,6,4.1,5.7c0.1-0.3,0.3-0.6,0.5-0.8C5,4.6,5.3,4.5,6.1,4.2l5.4-2c0.2-0.1,0.3-0.1,0.4-0.1
      c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.4,0.1l5.4,2c0.7,0.3,1.1,0.4,1.4,0.7c0.2,0.2,0.4,0.5,0.5,0.8C20,6,20,6.4,20,7.2V12z"
      />
      <line
        fill="none"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="12"
        y1="14.5"
        x2="12"
        y2="8.5"
      />
      <line
        fill="none"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="9"
        y1="11.5"
        x2="15"
        y2="11.5"
      />
    </g>
  ),
  "passkey-filled": () => (
    <g>
      <path
        fill="#0F1A2E"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.1,20.2v-3.5c-1-0.9-1.6-2.1-1.9-3.4H7.3c-1.2,0-1.8,0-2.3,0.2c-1.1,0.3-2,1.2-2.3,2.3
		c-0.2,0.5-0.2,3.8-0.2,5h10.7C13.1,20.6,13.1,20.4,13.1,20.2z"
      />
      <circle
        fill="#0F1A2E"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        cx="8.5"
        cy="6.2"
        r="4"
      />
      <path
        fill="#0F1A2E"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.9,21c-0.3-0.3-0.6-0.6-0.8-0.8c0,0,0-5,0-5c0-0.4-0.2-0.7-0.5-0.9c-1.1-0.6-1.7-1.8-1.6-3.1
		c0.2-1.4,1.1-2.4,2.5-2.7c0.2,0,0.4-0.1,0.6-0.1c1.5,0,2.8,1.1,3.1,2.6c0.2,1.4-0.6,2.8-2,3.4l-0.7,0.3l0.5,0.5
		c0.3,0.3,0.6,0.6,0.9,0.9c-0.3,0.3-0.5,0.5-0.8,0.8l-0.7,0.8l0.6,0.6c0.3,0.3,0.6,0.6,0.9,0.9c-0.3,0.4-1.6,1.6-1.8,1.9L17.9,21z"
      />
      <path
        fill="#ffffff"
        d="M17.1,11c0,0.6,0.4,1,1,1c0.5,0,1-0.5,1-1.1s-0.5-1-1-1C17.6,10,17.1,10.4,17.1,11z"
      />
    </g>
  ),
  "book-open-01": () => (
    <path
      d="M12 21L11.8999 20.8499C11.2053 19.808 10.858 19.287 10.3991 18.9098C9.99286 18.5759 9.52476 18.3254 9.02161 18.1726C8.45325 18 7.82711 18 6.57482 18H5.2C4.07989 18 3.51984 18 3.09202 17.782C2.71569 17.5903 2.40973 17.2843 2.21799 16.908C2 16.4802 2 15.9201 2 14.8V6.2C2 5.07989 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.07989 3 5.2 3H5.6C7.84021 3 8.96031 3 9.81596 3.43597C10.5686 3.81947 11.1805 4.43139 11.564 5.18404C12 6.03968 12 7.15979 12 9.4M12 21V9.4M12 21L12.1001 20.8499C12.7947 19.808 13.142 19.287 13.6009 18.9098C14.0071 18.5759 14.4752 18.3254 14.9784 18.1726C15.5467 18 16.1729 18 17.4252 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V6.2C22 5.07989 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H18.4C16.1598 3 15.0397 3 14.184 3.43597C13.4314 3.81947 12.8195 4.43139 12.436 5.18404C12 6.03968 12 7.15979 12 9.4"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "check-circle-broken": () => (
    <path
      d="M22 11.0857V12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69279 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572M22 4L12 14.01L9 11.01"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "chevron-down": () => (
    <path
      d="M6 9L12 15L18 9"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "chevron-left": () => (
    <path
      d="M15 18L9 12L15 6"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "chevron-right": () => (
    <path
      d="M9 18L15 12L9 6"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "chevron-up": () => (
    <path
      d="M18 15L12 9L6 15"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "eye-off": () => (
    <path
      d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  eye: () => (
    <g>
      <path
        d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  "face-smile": () => (
    <path
      d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14M15 9H15.01M9 9H9.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.5 9C15.5 9.27614 15.2761 9.5 15 9.5C14.7239 9.5 14.5 9.27614 14.5 9C14.5 8.72386 14.7239 8.5 15 8.5C15.2761 8.5 15.5 8.72386 15.5 9ZM9.5 9C9.5 9.27614 9.27614 9.5 9 9.5C8.72386 9.5 8.5 9.27614 8.5 9C8.5 8.72386 8.72386 8.5 9 8.5C9.27614 8.5 9.5 8.72386 9.5 9Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "file-search-02": () => (
    <path
      d="M14 11H8M10 15H8M16 7H8M20 10.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H11.5M22 22L20.5 20.5M21.5 18C21.5 19.933 19.933 21.5 18 21.5C16.067 21.5 14.5 19.933 14.5 18C14.5 16.067 16.067 14.5 18 14.5C19.933 14.5 21.5 16.067 21.5 18Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "globe-01": () => (
    <path
      d="M2 12H22M2 12C2 17.5228 6.47715 22 12 22M2 12C2 6.47715 6.47715 2 12 2M22 12C22 17.5228 17.5228 22 12 22M22 12C22 6.47715 17.5228 2 12 2M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22M12 2C9.49872 4.73835 8.07725 8.29203 8 12C8.07725 15.708 9.49872 19.2616 12 22"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "image-03": () => (
    <path
      d="M4.27209 20.7279L10.8686 14.1314C11.2646 13.7354 11.4627 13.5373 11.691 13.4632C11.8918 13.3979 12.1082 13.3979 12.309 13.4632C12.5373 13.5373 12.7354 13.7354 13.1314 14.1314L19.6839 20.6839M14 15L16.8686 12.1314C17.2646 11.7354 17.4627 11.5373 17.691 11.4632C17.8918 11.3979 18.1082 11.3979 18.309 11.4632C18.5373 11.5373 18.7354 11.7354 19.1314 12.1314L22 15M10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9ZM6.8 21L17.2 21C18.8802 21 19.7202 21 20.362 20.673C20.9265 20.3854 21.3854 19.9265 21.673 19.362C22 18.7202 22 17.8802 22 16.2L22 7.8C22 6.11984 22 5.27976 21.673 4.63803C21.3854 4.07354 20.9265 3.6146 20.362 3.32698C19.7202 3 18.8802 3 17.2 3L6.8 3C5.11984 3 4.27976 3 3.63803 3.32698C3.07354 3.6146 2.6146 4.07354 2.32698 4.63803C2 5.27976 2 6.11984 2 7.8L2 16.2C2 17.8802 2 18.7202 2.32698 19.362C2.6146 19.9265 3.07354 20.3854 3.63803 20.673C4.27976 21 5.11984 21 6.8 21Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "key-01": () => (
    <path
      d="M17 8.99994C17 8.48812 16.8047 7.9763 16.4142 7.58579C16.0237 7.19526 15.5118 7 15 7M15 15C18.3137 15 21 12.3137 21 9C21 5.68629 18.3137 3 15 3C11.6863 3 9 5.68629 9 9C9 9.27368 9.01832 9.54308 9.05381 9.80704C9.11218 10.2412 9.14136 10.4583 9.12172 10.5956C9.10125 10.7387 9.0752 10.8157 9.00469 10.9419C8.937 11.063 8.81771 11.1823 8.57913 11.4209L3.46863 16.5314C3.29568 16.7043 3.2092 16.7908 3.14736 16.8917C3.09253 16.9812 3.05213 17.0787 3.02763 17.1808C3 17.2959 3 17.4182 3 17.6627V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H7V19H9V17H11L12.5791 15.4209C12.8177 15.1823 12.937 15.063 13.0581 14.9953C13.1843 14.9248 13.2613 14.8987 13.4044 14.8783C13.5417 14.8586 13.7588 14.8878 14.193 14.9462C14.4569 14.9817 14.7263 15 15 15Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "laptop-02": () => (
    <path
      d="M21 16V7.2C21 6.0799 21 5.51984 20.782 5.09202C20.5903 4.71569 20.2843 4.40973 19.908 4.21799C19.4802 4 18.9201 4 17.8 4H6.2C5.07989 4 4.51984 4 4.09202 4.21799C3.71569 4.40973 3.40973 4.71569 3.21799 5.09202C3 5.51984 3 6.0799 3 7.2V16M4.66667 20H19.3333C19.9533 20 20.2633 20 20.5176 19.9319C21.2078 19.7469 21.7469 19.2078 21.9319 18.5176C22 18.2633 22 17.9533 22 17.3333C22 17.0233 22 16.8683 21.9659 16.7412C21.8735 16.3961 21.6039 16.1265 21.2588 16.0341C21.1317 16 20.9767 16 20.6667 16H3.33333C3.02334 16 2.86835 16 2.74118 16.0341C2.39609 16.1265 2.12654 16.3961 2.03407 16.7412C2 16.8683 2 17.0233 2 17.3333C2 17.9533 2 18.2633 2.06815 18.5176C2.25308 19.2078 2.79218 19.7469 3.48236 19.9319C3.73669 20 4.04669 20 4.66667 20Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "link-external-02": () => (
    <path
      d="M21 9.00001L21 3.00001M21 3.00001H15M21 3.00001L12 12M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "mail-02": () => (
    <path
      d="M21.5 18L14.8571 12M9.14286 12L2.50003 18M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "message-chat-square": () => (
    <path
      d="M10 15L6.92474 18.1137C6.49579 18.548 6.28131 18.7652 6.09695 18.7805C5.93701 18.7938 5.78042 18.7295 5.67596 18.6076C5.55556 18.4672 5.55556 18.162 5.55556 17.5515V15.9916C5.55556 15.444 5.10707 15.0477 4.5652 14.9683V14.9683C3.25374 14.7762 2.22378 13.7463 2.03168 12.4348C2 12.2186 2 11.9605 2 11.4444V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H14.2C15.8802 2 16.7202 2 17.362 2.32698C17.9265 2.6146 18.3854 3.07354 18.673 3.63803C19 4.27976 19 5.11984 19 6.8V11M19 22L16.8236 20.4869C16.5177 20.2742 16.3647 20.1678 16.1982 20.0924C16.0504 20.0255 15.8951 19.9768 15.7356 19.9474C15.5558 19.9143 15.3695 19.9143 14.9969 19.9143H13.2C12.0799 19.9143 11.5198 19.9143 11.092 19.6963C10.7157 19.5046 10.4097 19.1986 10.218 18.8223C10 18.3944 10 17.8344 10 16.7143V14.2C10 13.0799 10 12.5198 10.218 12.092C10.4097 11.7157 10.7157 11.4097 11.092 11.218C11.5198 11 12.0799 11 13.2 11H18.8C19.9201 11 20.4802 11 20.908 11.218C21.2843 11.4097 21.5903 11.7157 21.782 12.092C22 12.5198 22 13.0799 22 14.2V16.9143C22 17.8462 22 18.3121 21.8478 18.6797C21.6448 19.1697 21.2554 19.5591 20.7654 19.762C20.3978 19.9143 19.9319 19.9143 19 19.9143V22Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "message-text-square-01": () => (
    <path
      d="M7 8.5H12M7 12H15M9.68375 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V20.3355C3 20.8684 3 21.1348 3.10923 21.2716C3.20422 21.3906 3.34827 21.4599 3.50054 21.4597C3.67563 21.4595 3.88367 21.2931 4.29976 20.9602L6.68521 19.0518C7.17252 18.662 7.41617 18.4671 7.68749 18.3285C7.9282 18.2055 8.18443 18.1156 8.44921 18.0613C8.74767 18 9.0597 18 9.68375 18Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  passcode: () => (
    <path
      d="M12 12H12.005M17 12H17.005M7 12H7.005M5.2 7H18.8C19.9201 7 20.4802 7 20.908 7.21799C21.2843 7.40973 21.5903 7.71569 21.782 8.09202C22 8.51984 22 9.0799 22 10.2V13.8C22 14.9201 22 15.4802 21.782 15.908C21.5903 16.2843 21.2843 16.5903 20.908 16.782C20.4802 17 19.9201 17 18.8 17H5.2C4.0799 17 3.51984 17 3.09202 16.782C2.71569 16.5903 2.40973 16.2843 2.21799 15.908C2 15.4802 2 14.9201 2 13.8V10.2C2 9.0799 2 8.51984 2.21799 8.09202C2.40973 7.71569 2.71569 7.40973 3.09202 7.21799C3.51984 7 4.0799 7 5.2 7ZM12.25 12C12.25 12.1381 12.1381 12.25 12 12.25C11.8619 12.25 11.75 12.1381 11.75 12C11.75 11.8619 11.8619 11.75 12 11.75C12.1381 11.75 12.25 11.8619 12.25 12ZM17.25 12C17.25 12.1381 17.1381 12.25 17 12.25C16.8619 12.25 16.75 12.1381 16.75 12C16.75 11.8619 16.8619 11.75 17 11.75C17.1381 11.75 17.25 11.8619 17.25 12ZM7.25 12C7.25 12.1381 7.13807 12.25 7 12.25C6.86193 12.25 6.75 12.1381 6.75 12C6.75 11.8619 6.86193 11.75 7 11.75C7.13807 11.75 7.25 11.8619 7.25 12Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  passkey: () => (
    <g>
      <path
        d="M11 13.25H7.34C6.11 13.25 5.5 13.25 5 13.4C3.87 13.74 2.99 14.62 2.65 15.75C2.5 16.25 2.5 19.52 2.5 20.75M12.5 6.21C12.5 8.4 10.73 10.17 8.54 10.17C6.35 10.17 4.58 8.4 4.58 6.21C4.58 4.02 6.35 2.25 8.54 2.25C10.73 2.25 12.5 4.02 12.5 6.21Z"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4401 14.99C19.7601 15.32 20.0601 15.63 20.3701 15.94C20.7101 16.29 20.7101 16.28 20.3801 16.61C19.9801 17.02 19.6001 17.45 19.2001 17.88C19.5901 18.27 20.0301 18.73 20.4901 19.15C20.6801 19.33 20.6401 19.44 20.4901 19.6C19.7201 20.39 18.9601 21.19 18.1701 22C17.6501 21.47 17.1501 20.99 16.6801 20.49C16.6101 20.42 16.6101 20.26 16.6101 20.15C16.6101 18.5 16.6101 16.84 16.6101 15.19C16.6101 14.97 16.5401 14.86 16.3601 14.77C15.0301 14.03 14.3401 12.64 14.5301 11.18C14.7301 9.63 15.7601 8.38 17.4701 8.08C19.5401 7.71 21.3601 9.06 21.7101 11.06C21.9901 12.68 21.0401 14.33 19.4501 15.01L19.4401 14.99Z"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1201 10.99C17.1201 11.6 17.5501 12.03 18.1701 12.03C18.6801 12.03 19.1701 11.51 19.1701 10.97C19.1701 10.43 18.7001 9.98 18.1301 9.98C17.5501 9.98 17.1201 10.41 17.1201 10.99Z"
        fill="#0F1A2E"
      />
    </g>
  ),
  "phone-01": () => (
    <path
      d="M12 17.5H12.01M8.2 22H15.8C16.9201 22 17.4802 22 17.908 21.782C18.2843 21.5903 18.5903 21.2843 18.782 20.908C19 20.4802 19 19.9201 19 18.8V5.2C19 4.07989 19 3.51984 18.782 3.09202C18.5903 2.71569 18.2843 2.40973 17.908 2.21799C17.4802 2 16.9201 2 15.8 2H8.2C7.0799 2 6.51984 2 6.09202 2.21799C5.71569 2.40973 5.40973 2.71569 5.21799 3.09202C5 3.51984 5 4.0799 5 5.2V18.8C5 19.9201 5 20.4802 5.21799 20.908C5.40973 21.2843 5.71569 21.5903 6.09202 21.782C6.51984 22 7.07989 22 8.2 22ZM12.5 17.5C12.5 17.7761 12.2761 18 12 18C11.7239 18 11.5 17.7761 11.5 17.5C11.5 17.2239 11.7239 17 12 17C12.2761 17 12.5 17.2239 12.5 17.5Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  printer: () => (
    <path
      d="M18 7V5.2C18 4.0799 18 3.51984 17.782 3.09202C17.5903 2.71569 17.2843 2.40973 16.908 2.21799C16.4802 2 15.9201 2 14.8 2H9.2C8.0799 2 7.51984 2 7.09202 2.21799C6.71569 2.40973 6.40973 2.71569 6.21799 3.09202C6 3.51984 6 4.0799 6 5.2V7M6 18C5.07003 18 4.60504 18 4.22354 17.8978C3.18827 17.6204 2.37962 16.8117 2.10222 15.7765C2 15.395 2 14.93 2 14V11.8C2 10.1198 2 9.27976 2.32698 8.63803C2.6146 8.07354 3.07354 7.6146 3.63803 7.32698C4.27976 7 5.11984 7 6.8 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V14C22 14.93 22 15.395 21.8978 15.7765C21.6204 16.8117 20.8117 17.6204 19.7765 17.8978C19.395 18 18.93 18 18 18M15 10.5H18M9.2 22H14.8C15.9201 22 16.4802 22 16.908 21.782C17.2843 21.5903 17.5903 21.2843 17.782 20.908C18 20.4802 18 19.9201 18 18.8V17.2C18 16.0799 18 15.5198 17.782 15.092C17.5903 14.7157 17.2843 14.4097 16.908 14.218C16.4802 14 15.9201 14 14.8 14H9.2C8.0799 14 7.51984 14 7.09202 14.218C6.71569 14.4097 6.40973 14.7157 6.21799 15.092C6 15.5198 6 16.0799 6 17.2V18.8C6 19.9201 6 20.4802 6.21799 20.908C6.40973 21.2843 6.71569 21.5903 7.09202 21.782C7.51984 22 8.07989 22 9.2 22Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "refresh-ccw-04": () => (
    <path
      d="M17 18.8746C19.1213 17.3291 20.5 14.8255 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5H11.5M12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 9.17446 4.87867 6.67093 7 5.1254M11 22.4L13 20.4L11 18.4M13 5.6L11 3.6L13 1.6"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "switch-horizontal-01": () => (
    <path
      d="M20 17H4M4 17L8 13M4 17L8 21M4 7H20M20 7L16 3M20 7L16 11"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "thumbs-up": () => (
    <path
      d="M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "user-01": () => (
    <path
      d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "user-right-01": () => (
    <path
      d="M19 21L22 18M22 18L19 15M22 18H16M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "users-01": () => (
    <path
      d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
      stroke="#0F1A2E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  "burger-menu": () => (
    <g>
      <rect x="5" y="6" width="14" height="2" rx="1" />
      <rect x="5" y="11" width="14" height="2" rx="1" />
      <rect x="5" y="16" width="14" height="2" rx="1" />
    </g>
  ),
  cross: () => (
    <g>
      <path
        d="M6 6L18 18"
        stroke="#0F1A2E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 18L18 6"
        stroke="#0F1A2E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  "input-delete": () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#0F1A2E"
        d="M10,20c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10C4.5,0,0,4.5,0,10C0,15.5,4.5,20,10,20z"
      />
      <path
        fill="none"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.7,6.7l6.7,6.7 M6.7,13.3l6.7-6.7"
      />
    </g>
  ),
  magnifier: () => (
    <g>
      <circle
        fill="none"
        stroke="#0f1a2e"
        strokeWidth="2"
        cx="6.5"
        cy="6.5"
        r="5.5"
      />
      <path
        fill="none"
        stroke="#0f1a2e"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11,11l4,4"
      />
    </g>
  ),
  "real-arrow": () => (
    <g>
      <path
        d="M10 16L7 12L10 8"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.0001H17"
        stroke="#0F1A2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
} as const;

/**
 * Legacy icons partial transferred
 */
export const LegacyIcons = {
  // Default size 120,130  "../../../../assets/img/cp_onboarding.svg";
  "cpOnboarding": () => (
      <>
        <defs>
          <clipPath id="clippath">
            <circle fill={ "#149b5f" } cx="60" cy="60" r="60"/>
          </clipPath>
          <clipPath id="clippath-1">
            <circle fill={ "none" } cx="60" cy="60" r="60"/>
          </clipPath>
        </defs>
        <g>
          <circle fill={ "#149b5f" } cx="60" cy="60" r="60"/>
          <g clipPath={"url(#clippath)"} className="cls-8">
            <circle fill={ "#149b5f" } cx="60" cy="60" r="60"/>
          </g>
        </g>
        <g>
          <circle fill={ "#b3d362" } cx="113.43" cy="34.15" r="16.57"/>
          <path fill={"#fff"}
                d="M124.09,28.23l-.53-.54c-.59-.61-1.57-.62-2.17-.02l-10.38,10.16c-.27,.26-.7,.26-.96-.01l-3.1-3.17c-.75-.77-1.98-.78-2.75-.03l-.04,.04c-.72,.7-.73,1.85-.03,2.56l5.5,5.54c.49,.49,1.28,.5,1.77,.01l12.68-12.39c.6-.59,.61-1.55,.02-2.15Z"/>
        </g>
        <g className="cls-7" clipPath={ "url(#clippath-1)"}>
          <g>
            <g>
              <polyline fill={"#ebcf99"} points="43.12 96.57 49.56 89.37 46.9 81.4 81.7 80.07 81.73 89.87 87.64 95.21 86.93 129.38 43.63 135.59 45.06 95.64"/>
              <polyline fill={"#ebcf99"} points="43.12 96.57 49.56 89.37 46.9 81.4 81.7 80.07 81.73 89.87 87.64 95.21 86.93 129.38 43.63 135.59 45.06 95.64"/>
            </g>
            <path fill={"#ebcf99"} d="M79.51,105.06s-6.83,20.53-12.88,24.9c-7.5-4.71-15.79-25.04-15.79-25.04l-.06-22.37,27.67-.46,1.06,22.97Z"/>
            <path fill={"#e3bb82"} d="M50.57,92.18c9.4,18.2,31.67,17.04,31.67,17.04l.14-16.23-.92-7.05-34.87-4.86s3.99,10.59,3.99,11.09Z"/>
            <path fill={"#057ebd"}
                  d="M49.94,97.44l16.12,126.34s-8.41,41.62-57.91,76.55c-49.49,34.93-96.83,17.53-159.49,75.61,38.2-50.93,104.65-54.23,145.69-76.06,41.04-21.82,43.93-89.99,42.87-104.84-1.06-14.85-23.22-78.97-23.22-78.97l35.95-18.63Z"/>
            <path  fill={"#fff"}
                  d="M48.05,104.74c17.45,68.99-2.05,114.49-7.77,142.35-.54,2.28-9.44,27.37-9.44,27.37,0,0,35.16,19.33,86.1,1.32-12.18-59.36-10.64-93.18-27.21-175.43-1.1,2.83-6.25,12.82-18.55,14.56-14.16,1.6-22.02-8.87-23.14-10.17Z"/>
            <path fill={"#057ebd"}
                  d="M14.71,217.32c4.88,13.31-16.84,25.29-16.84,25.29,0,0-45.69-39.54-48.72-42.37-3.03-2.83-5.13-8.95-1.48-13.23C-35.79,167.64,10.87,113.58,21.9,107.16c4.91-2.86,8.98-4.58,12.24-5.32,.56-.13,7.67-.53,7.67-.53l-17.61,57.87-40.66,33.39s30.08,23.98,31.18,24.76Z"/>
            <path fill={"#057ebd"}
                  d="M55.23,101.32l-6.95-15.67-7.3,11.59s-10.45,2.4-18.94,4.79c-.89,.25-1.76,.5-2.6,.76,.78,.96,1.48,2,2.08,3.07,2.01,3.57,3.22,7.57,4.05,11.56,.78,3.74,1.22,7.55,1.33,11.37,.17,5.91-.54,11.82-1.59,17.63-.17,.95-5.46,10.69-5.64,11.63,33.7,38.45,13.02,112.66-80.41,169.42C119.71,281.66,40.3,175.4,55.23,101.32Z"/>
            <path fill={"#057ebd"}
                  d="M130.22,438.75c21.07-62.72,4.85-149.18,.55-167.17-18.96-77.53-14.91-72.21-12.88-154.79,.32-12.85-27.86-19.72-36.35-29.54-7.31,108.26,42.61,121.64,48.68,351.51Z"/>
            <path fill={"#057ebd"}
                  d="M113.83,109.01s17.18,0,20.62,20.62c3.44,20.62-1.15,74.91-1.15,74.91,0,0-.76,14.13-11.45,19.09s-15.65-9.54-15.65-9.54l7.64-105.07Z"/>
            <path
                d="M31.05,22.62c1.45,5.76,10.92,8.35,21.16,5.78,10.24-2.58,17.36-9.34,15.91-15.1-1.45-5.76-8.19-11.38-21.72-7.97-13.89,3.49-16.81,11.54-15.36,17.3Z"/>
            <path
                d="M62.81,28.53l-3.6-14.3,2.83,14.49s-11.14,3.48-14.63,6.86c-13.19,12.76-7.25,34.19-2.14,44.53-15-9.3-18.92-25.69-19.47-34.33-1.54-23.98,17.06-35.19,36.01-34.23,27.48,.28,35.2,40.39,24.08,55.42-4.31-10.99-23.08-38.43-23.08-38.43Z"/>
            <path
                d="M86.78,35.34c-4.2-16.7-21.15-26.84-37.85-22.63-16.7,4.2-26.83,21.15-22.63,37.85,4.2,16.7,21.15,26.84,37.85,22.63,16.7-4.2,26.84-21.15,22.63-37.85Z"/>
            <path fill={ "#ecd099" }
                  d="M51.39,19.12c13.84-3.29,27.73,5.13,31.24,18.86,.03,.08,.06,.16,.08,.25,.04,.22,.1,.47,.16,.76,.06,.3,.12,.59,.17,.88,1.26,6.5,4.46,23.59,3.34,33.26-1.08,9.22-8.42,18.18-10.32,20.39-1.46,1.88-3.54,3.29-6.04,3.89-2.62,.62-5.25,.23-7.47-.91-3.15-1.35-12.86-5.99-17.77-13.42-5.08-7.69-9.93-23.07-11.96-30.08-.27-.82-.53-1.66-.73-2.52-3.33-13.99,5.31-28.02,19.3-31.35Z"/>
            <path
                d="M64.16,33.34l-1.72-9.59-.09,9.68s-14.21,3.26-17.7,6.64c-13.19,12.76-7.86,25.25-2.71,37.81-10.03-7.5-15.63-21.68-16.18-30.33-1.54-23.98,14.26-36.27,33.22-35.3,12.49,5.49,17.03,14.01,27.28,22.28,3.84,15.27,3.62,36.99,.18,25.69-7.76-25.44-22.28-26.89-22.28-26.89Z"/>
            <path d="M57.91,34.17c-14.14,4.58-16.71,17.09-17.4,23.64-.64,6.05,.98,12.55,2.65,17.05-1.33-1.76-15.82-33.29,14.29-42.92-.02,0,.44,2.23,.46,2.23Z"/>
            <path stroke={"#e3bb82"} strokeLinecap={"round"} strokeMiterlimit={10} fill={ "none" } strokeWidth={ "1.26px" }
                  d="M71.19,73.74c-.3,.13-.61,.19-.97,.25-.95,.16-1.66,.69-2.1,1.47l-.09,.17c-.41,.9-1.24,1.59-2.28,1.77-1.03,.18-2.02-.18-2.7-.86l-.18-.21c-.67-.55-1.56-.82-2.48-.66-.31,.05-.57,.12-.86,.11"/>
            <path strokeWidth={".63px" } stroke={"#e3bb82"} strokeLinecap={"round"} strokeMiterlimit={10} fill={ "none" } d="M66.57,65.95s-1.82-2.41-2.04-3.73,.68-4.19,.68-4.19"/>
            <ellipse cx="75.39" cy="60.06" rx="2" ry="2.01" transform="translate(-13.89 23.72) rotate(-16.39)"/>
            <circle fill={"#fff"} cx="75.82" cy="59.36" r=".64"/>
            <path
                d="M68.42,59.82c-.56,.37,.42,.49,.42,.49,0,0,2.37-1.38,4.9-1.66,2-.23,3.59-.59,4.22-.18,.04,.03,.09,.06,.13,.1,.61,.51-.7,1.94-.7,1.94,0,0,2.3-1.71,2.37-3.29-5.31-.23-7.33-.05-11.34,2.6Z"/>
            <path
                d="M55.99,62.39c.62,.27-.28,.58-.28,.58,0,0-2.67-.89-5.19-.52-2,.3-3.59,.52-4.09,1.08-.03,.04-.07,.08-.1,.13-.46,.65,1.17,1.7,1.17,1.7,0,0-2.66-1.07-3.14-2.57,5.03-1.47,7.8-2.07,11.63-.4Z"/>
            <rect fill={ "#ecd099" } x="35.59" y="61.52" width="4.37" height="17.22" rx="2.18" ry="2.18" transform="translate(-21.63 17.06) rotate(-19.91)"/>
            <path
                d="M55.46,58.32c.66,.45-.09,1.53-1.29,1.42-1.2-.11-3.87-1.43-6.62-.93-2.76,.5-4.09,2.34-4.46,2.58s-.72-.15-.52-.47c.21-.33,2.07-2.54,5.2-2.99,3.13-.45,7.02-.05,7.68,.4Z"/>
            <path
                d="M65.43,49.85c-.59,.54,.31,1.5,1.48,1.22,1.17-.28,3.62-1.98,6.42-1.88,2.8,.1,4.39,1.72,4.79,1.91s.69-.25,.44-.54c-.25-.29-2.42-2.21-5.58-2.2-3.16,0-6.96,.96-7.54,1.51Z"/>
            <ellipse cx="52.85" cy="63.99" rx="2" ry="2.01" transform="translate(-15.91 17.52) rotate(-16.39)"/>
            <circle  fill={"#fff"} cx="53.1" cy="63.3" r=".64"/>
            <path fill={"#fff"} d="M73.97,80.11c-10.5,1.04-19.48-1.01-19.48-1.01,0,0,5.25,7.9,11.47,7.35,6.3-.56,7.87-5.97,8.01-6.34Z"/>
          </g>
          <circle fill={ "none" } cx="60" cy="60" r="60"/>
        </g>
      </>
  ),
  "cpOnboardingNoCheck": () => (
      <>
        <defs>
          <clipPath id="clippath">
            <circle fill={ "#149b5f" } cx="60" cy="60" r="60"/>
          </clipPath>
          <clipPath id="clippath-1">
            <circle fill={ "none" } cx="60" cy="60" r="60"/>
          </clipPath>
        </defs>
        <g>
          <circle fill={ "#149b5f" } cx="60" cy="60" r="60"/>
          <g clipPath={"url(#clippath)"} className="cls-8">
            <circle fill={ "#149b5f" } cx="60" cy="60" r="60"/>
          </g>
        </g>
        <g className="cls-7" clipPath={ "url(#clippath-1)"}>
          <g>
            <g>
              <polyline fill={"#ebcf99"} points="43.12 96.57 49.56 89.37 46.9 81.4 81.7 80.07 81.73 89.87 87.64 95.21 86.93 129.38 43.63 135.59 45.06 95.64"/>
              <polyline fill={"#ebcf99"} points="43.12 96.57 49.56 89.37 46.9 81.4 81.7 80.07 81.73 89.87 87.64 95.21 86.93 129.38 43.63 135.59 45.06 95.64"/>
            </g>
            <path fill={"#ebcf99"} d="M79.51,105.06s-6.83,20.53-12.88,24.9c-7.5-4.71-15.79-25.04-15.79-25.04l-.06-22.37,27.67-.46,1.06,22.97Z"/>
            <path fill={"#e3bb82"} d="M50.57,92.18c9.4,18.2,31.67,17.04,31.67,17.04l.14-16.23-.92-7.05-34.87-4.86s3.99,10.59,3.99,11.09Z"/>
            <path fill={"#057ebd"}
                  d="M49.94,97.44l16.12,126.34s-8.41,41.62-57.91,76.55c-49.49,34.93-96.83,17.53-159.49,75.61,38.2-50.93,104.65-54.23,145.69-76.06,41.04-21.82,43.93-89.99,42.87-104.84-1.06-14.85-23.22-78.97-23.22-78.97l35.95-18.63Z"/>
            <path  fill={"#fff"}
                   d="M48.05,104.74c17.45,68.99-2.05,114.49-7.77,142.35-.54,2.28-9.44,27.37-9.44,27.37,0,0,35.16,19.33,86.1,1.32-12.18-59.36-10.64-93.18-27.21-175.43-1.1,2.83-6.25,12.82-18.55,14.56-14.16,1.6-22.02-8.87-23.14-10.17Z"/>
            <path fill={"#057ebd"}
                  d="M14.71,217.32c4.88,13.31-16.84,25.29-16.84,25.29,0,0-45.69-39.54-48.72-42.37-3.03-2.83-5.13-8.95-1.48-13.23C-35.79,167.64,10.87,113.58,21.9,107.16c4.91-2.86,8.98-4.58,12.24-5.32,.56-.13,7.67-.53,7.67-.53l-17.61,57.87-40.66,33.39s30.08,23.98,31.18,24.76Z"/>
            <path fill={"#057ebd"}
                  d="M55.23,101.32l-6.95-15.67-7.3,11.59s-10.45,2.4-18.94,4.79c-.89,.25-1.76,.5-2.6,.76,.78,.96,1.48,2,2.08,3.07,2.01,3.57,3.22,7.57,4.05,11.56,.78,3.74,1.22,7.55,1.33,11.37,.17,5.91-.54,11.82-1.59,17.63-.17,.95-5.46,10.69-5.64,11.63,33.7,38.45,13.02,112.66-80.41,169.42C119.71,281.66,40.3,175.4,55.23,101.32Z"/>
            <path fill={"#057ebd"}
                  d="M130.22,438.75c21.07-62.72,4.85-149.18,.55-167.17-18.96-77.53-14.91-72.21-12.88-154.79,.32-12.85-27.86-19.72-36.35-29.54-7.31,108.26,42.61,121.64,48.68,351.51Z"/>
            <path fill={"#057ebd"}
                  d="M113.83,109.01s17.18,0,20.62,20.62c3.44,20.62-1.15,74.91-1.15,74.91,0,0-.76,14.13-11.45,19.09s-15.65-9.54-15.65-9.54l7.64-105.07Z"/>
            <path
                d="M31.05,22.62c1.45,5.76,10.92,8.35,21.16,5.78,10.24-2.58,17.36-9.34,15.91-15.1-1.45-5.76-8.19-11.38-21.72-7.97-13.89,3.49-16.81,11.54-15.36,17.3Z"/>
            <path
                d="M62.81,28.53l-3.6-14.3,2.83,14.49s-11.14,3.48-14.63,6.86c-13.19,12.76-7.25,34.19-2.14,44.53-15-9.3-18.92-25.69-19.47-34.33-1.54-23.98,17.06-35.19,36.01-34.23,27.48,.28,35.2,40.39,24.08,55.42-4.31-10.99-23.08-38.43-23.08-38.43Z"/>
            <path
                d="M86.78,35.34c-4.2-16.7-21.15-26.84-37.85-22.63-16.7,4.2-26.83,21.15-22.63,37.85,4.2,16.7,21.15,26.84,37.85,22.63,16.7-4.2,26.84-21.15,22.63-37.85Z"/>
            <path fill={ "#ecd099" }
                  d="M51.39,19.12c13.84-3.29,27.73,5.13,31.24,18.86,.03,.08,.06,.16,.08,.25,.04,.22,.1,.47,.16,.76,.06,.3,.12,.59,.17,.88,1.26,6.5,4.46,23.59,3.34,33.26-1.08,9.22-8.42,18.18-10.32,20.39-1.46,1.88-3.54,3.29-6.04,3.89-2.62,.62-5.25,.23-7.47-.91-3.15-1.35-12.86-5.99-17.77-13.42-5.08-7.69-9.93-23.07-11.96-30.08-.27-.82-.53-1.66-.73-2.52-3.33-13.99,5.31-28.02,19.3-31.35Z"/>
            <path
                d="M64.16,33.34l-1.72-9.59-.09,9.68s-14.21,3.26-17.7,6.64c-13.19,12.76-7.86,25.25-2.71,37.81-10.03-7.5-15.63-21.68-16.18-30.33-1.54-23.98,14.26-36.27,33.22-35.3,12.49,5.49,17.03,14.01,27.28,22.28,3.84,15.27,3.62,36.99,.18,25.69-7.76-25.44-22.28-26.89-22.28-26.89Z"/>
            <path d="M57.91,34.17c-14.14,4.58-16.71,17.09-17.4,23.64-.64,6.05,.98,12.55,2.65,17.05-1.33-1.76-15.82-33.29,14.29-42.92-.02,0,.44,2.23,.46,2.23Z"/>
            <path stroke={"#e3bb82"} strokeLinecap={"round"} strokeMiterlimit={10} fill={ "none" } strokeWidth={ "1.26px" }
                  d="M71.19,73.74c-.3,.13-.61,.19-.97,.25-.95,.16-1.66,.69-2.1,1.47l-.09,.17c-.41,.9-1.24,1.59-2.28,1.77-1.03,.18-2.02-.18-2.7-.86l-.18-.21c-.67-.55-1.56-.82-2.48-.66-.31,.05-.57,.12-.86,.11"/>
            <path strokeWidth={".63px" } stroke={"#e3bb82"} strokeLinecap={"round"} strokeMiterlimit={10} fill={ "none" } d="M66.57,65.95s-1.82-2.41-2.04-3.73,.68-4.19,.68-4.19"/>
            <ellipse cx="75.39" cy="60.06" rx="2" ry="2.01" transform="translate(-13.89 23.72) rotate(-16.39)"/>
            <circle fill={"#fff"} cx="75.82" cy="59.36" r=".64"/>
            <path
                d="M68.42,59.82c-.56,.37,.42,.49,.42,.49,0,0,2.37-1.38,4.9-1.66,2-.23,3.59-.59,4.22-.18,.04,.03,.09,.06,.13,.1,.61,.51-.7,1.94-.7,1.94,0,0,2.3-1.71,2.37-3.29-5.31-.23-7.33-.05-11.34,2.6Z"/>
            <path
                d="M55.99,62.39c.62,.27-.28,.58-.28,.58,0,0-2.67-.89-5.19-.52-2,.3-3.59,.52-4.09,1.08-.03,.04-.07,.08-.1,.13-.46,.65,1.17,1.7,1.17,1.7,0,0-2.66-1.07-3.14-2.57,5.03-1.47,7.8-2.07,11.63-.4Z"/>
            <rect fill={ "#ecd099" } x="35.59" y="61.52" width="4.37" height="17.22" rx="2.18" ry="2.18" transform="translate(-21.63 17.06) rotate(-19.91)"/>
            <path
                d="M55.46,58.32c.66,.45-.09,1.53-1.29,1.42-1.2-.11-3.87-1.43-6.62-.93-2.76,.5-4.09,2.34-4.46,2.58s-.72-.15-.52-.47c.21-.33,2.07-2.54,5.2-2.99,3.13-.45,7.02-.05,7.68,.4Z"/>
            <path
                d="M65.43,49.85c-.59,.54,.31,1.5,1.48,1.22,1.17-.28,3.62-1.98,6.42-1.88,2.8,.1,4.39,1.72,4.79,1.91s.69-.25,.44-.54c-.25-.29-2.42-2.21-5.58-2.2-3.16,0-6.96,.96-7.54,1.51Z"/>
            <ellipse cx="52.85" cy="63.99" rx="2" ry="2.01" transform="translate(-15.91 17.52) rotate(-16.39)"/>
            <circle  fill={"#fff"} cx="53.1" cy="63.3" r=".64"/>
            <path fill={"#fff"} d="M73.97,80.11c-10.5,1.04-19.48-1.01-19.48-1.01,0,0,5.25,7.9,11.47,7.35,6.3-.56,7.87-5.97,8.01-6.34Z"/>
          </g>
          <circle fill={ "none" } cx="60" cy="60" r="60"/>
        </g>
      </>
  ),
} as const;
