import clsx from "clsx";
import {LegacyIcon, UntitledColorizeIcon, UntitledIcon} from "../icons";
import { Spacing } from "../Spacing";
import { CardGroupHeaderProps } from "./cardGroupHeader.interface";

import "./cardGroupHeader.scss";
import React from "react";

const CardGroupHeader: React.FC<CardGroupHeaderProps> = ({
  title,
  description,
  status = "info",
  noIcon = false,
  icon="face-smile",
  legacyIcon
}) => {

  const getUntitledIcon = (status:"success" | "warning" | "info") => {
    if (status === "warning") {
      return "shield-warning";
    } else {
      return "shield-success";
    }
  };

  return (
      <Spacing
          flexDirection="column"
          className="card-group-header"
          alignItems="center"
      >
        {!noIcon && !legacyIcon && (
            <div className={clsx("icon-wrapper", status)}>
              {status === "info" ? (
                  <UntitledColorizeIcon name={icon}/>
              ) : <UntitledIcon name={getUntitledIcon(status)}/>}
            </div>
        )}
        {!noIcon && legacyIcon && (<LegacyIcon name={legacyIcon}/>)}
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </Spacing>
  );
};

export { CardGroupHeader };
