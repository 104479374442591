import React from 'react';
import './link.scss';
import {LinkProps} from "../../../../shared/interfaces";

/**
 * Primary UI component for user interaction
 */
export const Link : React.FC<LinkProps> = (props) => {

  let newClassName = props.className || "";

  if (props.underline === true) {
    newClassName += " underline";
  }
  if (props.strong === true) {
    newClassName += " strong";
  }

  if (props.link === undefined) {
    return (
        <span onClick={props.onClick} id={props.id} aria-label={props.id} className={["link", props.color ? props.color : "", newClassName].join(' ')}>
          {props.text || props.children || "Link"}
        </span>
    );
  }

  return (
      <a onClick={props.onClick} id={props.id} aria-label={props.id} href={props.link} className={["link", props.color ? props.color : "", newClassName].join(' ')}
        target={props.openInNewTab ? '_blank' : ''} rel="noopener noreferrer">
        {props.text || props.children || props.link}
      </a>
  );
};
