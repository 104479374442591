import React, {useEffect, useRef, useState} from "react";
import "./navigation.scss";
import {NavigationEntryProps, NavigationProps} from "../../../../shared/interfaces";
import {useTranslation} from "react-i18next";
import {Paragraph} from "../Paragraph/Paragraph";
import {NavigationEntry} from "./NavigationEntry";
import {I18NHeading} from "../I18NHeading/I18NHeading";
import i18next from "i18next";
import {UntitledColorizeIcon, UntitledIcon} from "../icons";
import {Button} from "../Button/Button";
import {Spacing} from "../Spacing";
import {useActiveTheme} from "../Redesign/activeTheme";
import {Toggle} from "../Toggle/Toggle";
import {useNavigate} from "react-router-dom";

interface NavigationMenuProps {
  muid: string | undefined,
  username: string | undefined,
  toggleCollapse: () => void,
  collapseVisible: boolean,
  entries: NavigationEntryProps[]
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({muid, username, toggleCollapse, collapseVisible, entries}) => {
  const {t} = useTranslation();
  const {setTheme, activeTheme} = useActiveTheme();

  const [, setCurrentLang] = useState("de");

  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    function handleClickOutside(event: MouseEvent) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node) && collapseVisible) {
        toggleCollapse();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [collapseVisible, toggleCollapse]);

  const changeLanguage = () => {

    const lang = i18next.resolvedLanguage?.includes("de") ? "en" : "de";

    i18next.changeLanguage(lang, (err) => {
      setCurrentLang(lang);
      if (err) return console.log("something went wrong loading", err);
    });
  };

  const getUpperInformation = () => {
    if (collapseVisible) {
      // in open state
      if (username && muid) {
        return (
            <div className="navigation-welcome">
              <Paragraph className="strong name" text={username}/>
              <Paragraph className="mail" text={muid}/>
            </div>
        );
      } else {
        return (
            <div className={"navigation-welcome"}>
              <I18NHeading type={"h4"} i18n={"navigation.welcome"}/>
            </div>
        );
      }
    } else {
      // in close state
      return (<div className={"navigation-menu-heading"}/>);
    }
  };

  const combineMenuEntries = () => {
    const navigationEntries = entries.map((entry) => {
      if (entry.id === "navigation-logout") {
        return (
            <Button
                id={entry.id}
                key={entry.id}
                onClick={entry.onClick}
                label={t(entry.i18nLabel)}
            />
        );
      }
      return (
          <NavigationEntry
              key={entry.id}
              id={entry.id}
              i18nLabel={entry.i18nLabel}
              icon={entry.icon}
              onClick={entry.onClick}
              i18nNote={entry.i18nNote}
          />
      );
    });

    return (
        <>
          <NavigationEntry
              id={"navigation-theme-switch"}
              i18nLabel={"navigation.changeTheme.label"}
              onClick={() => {
              }}
          >
            <Toggle
                id={"navigation-theme-switch-toggle"}
                toggleState={activeTheme !== "old"}
                setToggleState={(isNew) => setTheme(!isNew)}
            />
          </NavigationEntry>

          <NavigationEntry
              id={"navigation-language"}
              icon={"globe-01"}
              i18nLabel={t("navigation.changeLang.label")}
              onClick={changeLanguage}
          />

          {navigationEntries}
        </>
    );
  };

  return (<div className="navigation-wrapper" ref={sidebarRef}>
    <div className="navigation-header" onClick={toggleCollapse}>
      {collapseVisible ? (
          <UntitledColorizeIcon
              id="loginCollapse"
              className="toggle-container"
              style={{marginRight: "16px", height: "50px"}}
              name="cross"
          />
      ) : (
          <UntitledColorizeIcon
              id="loginCollapse"
              className="toggle-container"
              name="burger-menu"
          />
      )}
    </div>
    <>
      {getUpperInformation()}
      {collapseVisible && (
          <div className="navigation-content">{combineMenuEntries()}</div>
      )}
    </>
  </div>);
};

/**
 * Primary UI component for user interaction
 */
export const Navigation: React.FC<NavigationProps> = ({ username, muid, entries, subpage,title}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [collapseContentVisible, setCollapseContentVisible] = useState(false);

  const toggleCollapse = () => {
    if (collapseContentVisible) {
      setCollapseContentVisible(false);
    } else {
      setCollapseContentVisible(true);
    }
  };

  return (
      <nav role="navigation" aria-label={"Navigation"} className={collapseContentVisible ? "active navigation" : ""}>
        <Spacing fullWidthChildren={false} alignItems="center" gap="16">
          <button className="storybook-icon-button navigation-header-button" onClick={() => navigate("/cp/fidoCredentials")} style={{cursor: "pointer"}}>
            {subpage ? (
                  <UntitledColorizeIcon
                      style={{
                        fill: "var(--button-label-color)",
                        color: "var(--button-label-color)",
                      }}
                      name="real-arrow"
                      role="img"
                  />
               ) : (
                <UntitledIcon name="logo" viewBox="0 0 32 16"/>)}
            {title && (
                <span className="navigation-title">{t(title)}</span>
            )}
          </button>
        </Spacing>
        <NavigationMenu muid={muid} username={username}
                        toggleCollapse={toggleCollapse}
                        collapseVisible={collapseContentVisible}
                        entries={entries}
        />
      </nav>
  );
};
