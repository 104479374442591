import React, { useState } from "react";
import "./iconButton.scss";
import { IconButtonProps } from "../../../../shared/interfaces";
import { Icon } from "../Icon/Icon";
import { Heading } from "../Heading/Heading";
import { UntitledColorizeIcon } from "../icons";
import { New, Old, Redesign } from "../Redesign";

/**
 * Primary UI component for user interaction
 */
export const IconButton: React.FC<IconButtonProps> = (props) => {
  const [isActive, setIsActive] = useState(props.initiallyActive ?? false);

  const handleClick = () => {
    setIsActive(!isActive);
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <button
      id={props.buttonId}
      aria-label={props.buttonId}
      type="button"
      disabled={props.disabled}
      className={["storybook-icon-button", props.className].join(" ")}
      onClick={handleClick}
      style={props?.style}
    >
      {props.label && (
        <Heading
          text={props.label}
          type={props.headlineSize ? props.headlineSize : "h3"}
        />
      )}
      {props.icon && (
        <Redesign>
          <New>
            <UntitledColorizeIcon
              name={isActive ? "chevron-down" : "chevron-left"}
              alt={props.alt}
            />
          </New>
          <Old>
            <Icon
              className={isActive && !props.preventTurning ? "active" : ""}
              icon={props.icon}
              sizeInPx={props.iconSizeInPx}
              alt={props.alt}
            />
          </Old>
        </Redesign>
      )}
    </button>
  );
};
