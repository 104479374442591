import React from 'react';
import './icons.scss';

import { UntitledIcons, UntitledColorizeIcons, LegacyIcons } from './Icons';
import {
  UntitledIconProps,
  UntitledColorizeIconProps,
  LegacyIconProps,
  UntitledIconNames,
  UntitledColorizeIconNames,
  LegacyIconNames,
} from './icon.interface';
import clsx from 'clsx';

function filterIcons(variant: IconVariants, name: string) {

  switch (variant) {
    case 'colorize':
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (UntitledColorizeIcons as any)[name]();
    case 'legacy':
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (LegacyIcons as any)[name]();
    default:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (UntitledIcons as any)[name]();
  }
}

type IconVariants = 'colorize' | 'untitled' | 'legacy';

const Icon: React.FC<{
  variant: IconVariants;
  role: 'img' | 'presentation';
  name: UntitledIconNames | UntitledColorizeIconNames | LegacyIconNames;
  title?: string;
  size: number | { width: number, height: number};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
}> = ({ size, props, title, variant, name, role }) => {

  let width: number;
  let height: number;
  if (typeof size === "number") {
    width = size;
    height = size;
  } else {
    width = size.width;
    height = size.height;
  }

  return (
    <svg
      fill={variant === 'colorize' ? undefined : 'currentColor'}
      // eslint-disable-next-line react/prop-types
      className={clsx('icon', `icon--${variant}`, props.className)}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      role={role}
      data-testid="icon"
      {...props}
    >
      {title && <title>{title}</title>}
      {filterIcons(variant, name)}
    </svg>
  );
};

export const UntitledIcon: React.FC<UntitledIconProps> = ({ role = 'img', name, size = 32, ...props }) => {
  return Icon({ variant: 'untitled', size, props, name, role });
};

export const UntitledColorizeIcon: React.FC<UntitledColorizeIconProps> = ({ role = 'img', name, size = 24, ...props }) => {
  return Icon({ variant: 'colorize', size, props, name, role });
};

export const LegacyIcon: React.FC<LegacyIconProps> = ({ role = 'img', name, size = {width: 130, height: 120}, ...props }) => {
  return Icon({ variant: 'legacy', size, props, name, role });
};
