/**
 * Gets and sets a unique tab id
 * On new tab: The tab id newly generated.
 * On F5 reload: The tab id remains stable by storing it in sessionStorage.
 * On Clone/Copy: The cloned Tab will have a new tab id.
 *
 * The tab id itself is stored in the session storage.*
 * All tabIds are stored in the localstorage.
 * Before the page unloads, the tab's unique ID is cleared to ensure it can be reused upon page reload.
 */
let runOnce = false;

function updateLocalStorage(tabId:string) {

  const tabIds = localStorage.getItem('tabIds');
  if (tabIds) {
    localStorage.setItem('tabIds', tabIds + "," + tabId);
  } else {
    localStorage.setItem('tabIds', tabId);
  }

  function handleBeforeUnload() {
    const tabIds = localStorage.getItem('tabIds');
    if (tabIds) {
      const ids = tabIds.split(',').filter(id => id !== tabId);
      localStorage.setItem('tabIds', ids.join(','));
    }
  }

  // We need to clean up the local storage on page quit, otherwise F5 and Clone cannot be distinguished.
  window.addEventListener('beforeunload', handleBeforeUnload);
}

export function useTabId() {

  let tabId = sessionStorage.getItem('tabId');

  if (!runOnce) {

    if (tabId === null) {
      // Init New
      tabId = crypto.randomUUID();
      sessionStorage.setItem('tabId', tabId);
    } else {

      // F5 test or new cloned tab check
      const tabIds = localStorage.getItem('tabIds');

      if (tabIds && tabIds.includes(tabId)) {
        // cloned tab
        tabId = crypto.randomUUID();
        sessionStorage.setItem('tabId', tabId);
      }
      // else F5 tab
    }

    updateLocalStorage(tabId);
    runOnce = true;
  }

  return tabId || "";
}
