import clsx from "clsx";
import { BadgeProps } from "./Badge.interface";
import "./badge.scss";
import React from "react";

const Badge: React.FC<BadgeProps> = ({ label, status = "success" }) => (
  label ? <span className={clsx("badge", `badge-${status}`)}>{label}</span> : <></>
);

export { Badge };
