import React from 'react';
import './authenticatorList.scss';
import {AuthenticatorListEntryProps, AuthenticatorListProps} from "../../../../shared/interfaces";
import {AuthenticatorListEntry} from "./AuthenticatorListEntry";
import {EmptyAuthenticatorList} from "./EmptyAuthenticatorList";

/**
 * Primary UI component for user interaction
 */
export const AuthenticatorList : React.FC<AuthenticatorListProps> = (props) => {

  const authenticatorWithNameExists = (tokenName: string) => {
    return !props.authenticators?.every(device => device.name !== tokenName);
  };

  const noAuthenticatorsRegistered = props.authenticators.length === 0;

  if (noAuthenticatorsRegistered) {
    return (
        <EmptyAuthenticatorList listId={props.listID ? props.listID + "-empty" : undefined}/>
    );
  } else {

    const onDeleteDisabled = props.authenticators.length <= 1;
    const authenticatorListEntries = props.authenticators.map((authi: AuthenticatorListEntryProps, i) => {
      return <AuthenticatorListEntry key={i} type={authi.type} name={authi.name}
                                     id={props.listID + "-" + i}
                                     isLast={false}
                                     creationDate={authi.creationDate}
                                     authenticatorWithNameExists={authenticatorWithNameExists}
                                     deviceType={authi.deviceType}
                                     lastUsedDate={authi.lastUsedDate}
                                     onDelete={authi.onDelete}
                                     onDeleteDisabled={onDeleteDisabled}
                                     saveNewName={authi.saveNewName}
                                     setModal={props.setModal}
                                     createdWithRequireResidentKey={authi.createdWithRequireResidentKey}
                                     recentlyUsed={authi.recentlyUsed}
                                     isOnlyAuthenticator={props.authenticators.length === 1}
                                     registerNewAuthAction={props.registerNewAuthAction}
                                     onCloseModal={props.onCloseModal}
                                     testAuthenticator={authi.testAuthenticator}
                                     onSwitchAccount={props.onSwitchAccount}
      />;
    });
    
    const authenticatorListEntries1 = authenticatorListEntries.slice(0, Math.ceil(authenticatorListEntries.length / 2));
    const authenticatorListEntries2 = authenticatorListEntries.slice(Math.ceil(authenticatorListEntries.length / 2), authenticatorListEntries.length);
    
    return (
        <>
          <div className="authenticatorList">
            <div className={"column"}>
              {authenticatorListEntries1}
            </div>
            <div className={"column"}>
              {authenticatorListEntries2}
            </div>
          </div>
        </>
    );
  }
};
