import React, {FunctionComponent, useEffect, useState} from "react";
import {useGlobalState} from "../GlobalState";

/**
 * Returns a function that starts the account switch process. See AccountSwitchHandler for details.
 */
export function useSwitchAccount() {
  const [, setSwitchAccount] = useGlobalState("switchAccount");
  return (username?:string) => setSwitchAccount(username ?? true);
}

/**
 * When switchAccount is set to true, this component first logs out from ADFS and then redirects to Azure/ADFS login.
 * After the redirect from that login back to the portal, the component is reloaded and will again log out from ADFS.
 * For details why this ADFS logout is necessary, ask Dennis. ;-) It has something to do with the fact that the
 * ADFS does not support multiple logins at the same time, but Azure does.
 */
export const AccountSwitchHandler: FunctionComponent = () => {
  const [switchAccount] = useGlobalState("switchAccount");
  const [, setIsLoading] = useGlobalState("isLoading");
  const [doAdfsLogout, setDoAdfsLogout] = useState<boolean>(false);

  useEffect(() => {
    if (switchAccount || sessionStorage.getItem("doAdfsLogout") === "true") {
      // user clicked an account switch button
      // OR user was redirected from Azure/ADFS login
      setIsLoading({loadingType: "standard"});
      setDoAdfsLogout(true);
    }
  }, [switchAccount]);

  function onIframeLoaded() {
    setDoAdfsLogout(false);
    setIsLoading({loadingType: null});

    // switchAccount indicates that this must be the first ADFS logout, because the second will
    // happen after the redirect from Azure/ADFS login where the state will be reset to false,
    // the sessionStorage is used to keep the state during the redirect to login and back
    if (switchAccount) {
      sessionStorage.setItem("doAdfsLogout", "true");

      let loginUrl:string;
      if (switchAccount === true || typeof switchAccount == 'object') {
        loginUrl = "/cp/oauth2/authorization/oidc?force_login=true";
      } else {
        loginUrl = "/cp/oauth2/authorization/oidc?force_login=true&username=" + switchAccount;
      }

      window.location.assign(loginUrl);
    } else {
      sessionStorage.removeItem("doAdfsLogout");
      sessionStorage.removeItem("portalLoginName");
    }
  }

  const adfsLogoutUrl: string = window.location.hostname.startsWith("me-test")
      ? "https://fs-test.merckgroup.com/adfs/ls/?wa=wsignout1.0"
      : "https://fs.merckgroup.com/adfs/ls/?wa=wsignout1.0";

  return (<>
    {doAdfsLogout && <iframe src={adfsLogoutUrl} onLoad={onIframeLoaded} style={{display: 'none'}}/>}
  </>);
};
