interface global {
    from:  string | undefined,
    portal_version_info: string;
    brand: string;
}

const uiParameterContainer: HTMLElement | null = document.getElementById("uiParameter");

const globals: global = {
    from: undefined,
    portal_version_info: "Version 0.0.1",
    brand: "EMD"
};

if (uiParameterContainer != null) {
    const portalVersionInfo = uiParameterContainer.dataset.portalVersionInfo;
    const brand = uiParameterContainer.dataset.brand;
    const from = uiParameterContainer.dataset.from;

    if (from && from !== "%from%") {
        globals.from = from;
    }

    if (portalVersionInfo && portalVersionInfo !== "%portalVersionInfo%") {
        globals.portal_version_info = portalVersionInfo;
    }
    
    if (brand && brand !== "%brand%") {
        globals.brand = brand;
    }
}

window.globals = globals;
