import React from 'react';
import './paragraph.scss';
import {ParagraphProps} from "../../../../shared/interfaces";

/**
 * Primary UI component for user interaction
 */
export const Paragraph : React.FC<ParagraphProps> = (props) => {

    return (
        <p id={props.id} aria-label={props.id} className={[props.color, props.className].join(' ')} onClick={props.onClick} title={props.title}>
            {props.text}
        </p>
    );
};
