import React from 'react';
import "./authenticatorListEntry.scss";
import {Icon} from "../Icon/Icon";
import {EmptyAuthenticatorListProps} from "../../../../shared/interfaces";
import {I18NParagraph} from "../I18NParagraph/I18NParagraph";

/**
 * Primary UI component for user interaction
 */
export const EmptyAuthenticatorList : React.FC<EmptyAuthenticatorListProps> = (props) => {
      return (
          <div aria-label={props.listId} id={props.listId} className="authenticatorListEntry empty">
            <Icon icon={"smiley_unhappy"} sizeInPx={40}/>
            <I18NParagraph i18n={"portal.fidoCredentials.noAuthenticators"}/>
          </div>
      );
    }
;

