import React, {useEffect} from 'react';
import "./loading.scss";
import {Paragraph} from "../Paragraph/Paragraph";
import {LoadingProps} from "../../../../shared/interfaces";
import {useTranslation} from "react-i18next";
import {I18NHeading} from "../I18NHeading/I18NHeading";
import {LoadingAzure} from "./LoadingAzure";
import {LoadingOffline} from "./LoadingOffline";

function checkScreenSize(leftChild:JSX.Element|undefined, rightChild:JSX.Element|undefined) {
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  // contains padding, margins, etc
  const valueToSubtract = Math.round(windowHeight * 0.05);

  const leftElement = document.getElementById("left-loading-element");
  const rightElement = document.getElementById("right-loading-element");


  if (leftChild && leftElement) {
    calculateAndApplyAnimation(leftElement, windowHeight, valueToSubtract);
  }

  if (rightChild && rightElement) {
    calculateAndApplyAnimation(rightElement, windowHeight, valueToSubtract);
  }
}

function calculateAndApplyAnimation(elem: HTMLElement, windowHeight: number, valueToSubtract: number)  {
  const leftSideScrollHeightDiff = elem.scrollHeight || 0;
  const leftSideOffsetHeightDiff = elem.offsetHeight || 0;

  let start = 0, end = 0;

  if (leftSideOffsetHeightDiff && leftSideScrollHeightDiff > (windowHeight - valueToSubtract)) {
    const sum = windowHeight - leftSideOffsetHeightDiff;
    start = -1 * (sum * 0.6);
    end = sum * 0.7;
  }

  elem.animate([
    {transform: "translateY(" + start + "px)", offset: 0},
    {transform: "translateY(" + end + "px)", offset: 0.5},
    {transform: "translateY(" + start + "px)", offset: 1}
  ], {
    duration: 30000,
    iterations: Infinity
  });
}

/**
 * Primary UI component for user interaction
 */
export const Loading: React.FC<LoadingProps> = ({
                                                  loadingType, i18n, leftChild, rightChild,
                                                  fs_domain,
                                                  az_step, az_lastMobileDigits, az_validEntropyNumber, az_onCancel, az_onSubmit, az_connected, props
                                                }) => {

  const {t} = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      checkScreenSize(leftChild, rightChild);
    }, 1000);
  });

  React.useEffect(() => {
    window.addEventListener('resize', () => checkScreenSize(leftChild, rightChild));

    // cleanup this component
    return () => {
      window.removeEventListener('resize',() => checkScreenSize(leftChild, rightChild));
    };
  });

  const returnDefault = () => {
    return <></>;
  };

  switch (loadingType) {
    case "standard": {
      return (
          <div id="loading">
            <div className={"loading-side"}>
              <div className={"scroll-animated"} id={"left-loading-element"}>
                {leftChild}
              </div>
            </div>
            <div className={"loading-middle"}>
              <Paragraph text={t(i18n ? i18n : 'global.loadingSpinner')}/>
              <div className={"loading-animation-wrapper"}>
                <div className="loading-animation"/>
              </div>
            </div>
            <div className={"loading-side"}>
              <div className={"scroll-animated"} id={"right-loading-element"}>
                {rightChild}
              </div>
            </div>
          </div>
      );
    }
    case "fidostick_register": {
      if (!fs_domain) {
        return returnDefault();
      }
      return (
          <div id="loading">
            <div className={"loading-side"}>
              <div className={"scroll-animated"} id={"left-loading-element"}>
                {leftChild}
              </div>
            </div>
            <div className={"loading-middle"}>
              <Paragraph text={t(i18n ? i18n : 'global.loadingSpinner')}/>
              <div className={"loading-animation-wrapper"}>
                <div className="loading-animation"/>
              </div>
              <I18NHeading className="loading-heading" id="loading-waiting-authenticator" type="h3"
                           i18n={'fidoRegistration.loading.title'}/>
              <Paragraph className="loading-text" text={t('fidoRegistration.loading.textRegister', {domain: fs_domain})}/>
            </div>
            <div className={"loading-side"}>
              <div className={"scroll-animated"} id={"right-loading-element"}>
                {rightChild}
              </div>
            </div>
          </div>
      );
    }
    case "fidostick_authenticate": {
      if (!fs_domain) {
        return returnDefault();
      }
      return (
          <div id="loading">
            <div className={"loading-side"}>
              <div className={"scroll-animated"} id={"left-loading-element"}>
                {leftChild}
              </div>
            </div>
            <div className={"loading-middle"}>
              <Paragraph text={t(i18n ? i18n : 'global.loadingSpinner')}/>
              <div className={"loading-animation-wrapper"}>
                <div className="loading-animation"/>
              </div>
              <I18NHeading className="loading-heading" id="loading-waiting-authenticator" type="h3"
                           i18n={'fidoRegistration.loading.title'}/>
              <div className={"list-wrapper"}>
                <Paragraph className="loading-text" text={t('fidoRegistration.loading.textAuthenticate', {domain: fs_domain})}/>
              </div>
            </div>
            <div className={"loading-side"}>
              <div className={"scroll-animated"} id={"right-loading-element"}>
                {rightChild}
              </div>
            </div>
          </div>
      );
    }
    case "azure": {
      if (!az_step || !az_lastMobileDigits || !az_onCancel || !az_onSubmit) {
        return returnDefault();
      }
      return <LoadingAzure connected={az_connected !== undefined ? az_connected : true} step={az_step} lastMobileDigits={az_lastMobileDigits}
                           validEntropyNumber={az_validEntropyNumber} onCancel={az_onCancel} onSubmit={az_onSubmit}/>;
    }
    case "offline": {
      return <LoadingOffline onCancel={props?.onCancel} onSubmit={props?.onSubmit}/>;
    }
    default: {
      return returnDefault();
    }
  }
};
