import {httpPost} from "./fetch-client";
import {apiPath} from "./apiUrl";
import {signInFunction} from "../pages/LoginPage";
import {useCookies} from "react-cookie";
import {useGlobalState} from "../GlobalState";

export const useFetchToken = () => {

  const [cookies] = useCookies();
  const [, setIsLoading] = useGlobalState("isLoading");

  return async (subject:string, claims:object | undefined) => {
    try {
      setIsLoading({loadingType: "standard"});

      const {status, payload: token} = await httpPost<{ token: string }>(
          `${apiPath}/user/token`,
          { subject, claims },
          cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      if (status == 200 && token) {
        return token.token;
      }

    } finally {
      setIsLoading({loadingType: null});
    }

    return "";
  };
};
