import React, { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { contextPath } from "../utils/apiUrl";
import { FidoCredentials } from "../pages/FidoCredentials";
import { PasskeysPage } from "../pages/PasskeysPage";
import { AdvancedProtectionPage } from "../pages/AdvacendProtectionPage";
import { ManageAccountsPage } from "../pages/ManageAccounts";
import {LessSecureSignInPage} from "../pages/LessSecureSignIns";
import {useUser} from "../components/UserContext";

export const AuthRoutes: FunctionComponent = () => {
  const { user } = useUser();

  return (
    <Routes>
      <Route
        path={`${contextPath}/fidoCredentials/*`}
        element={<>{user && <FidoCredentials user={user} />}</>}
      />
      <Route
        path={`${contextPath}/passkeys/*`}
        element={<>{user && <PasskeysPage user={user} />}</>}
      />
      <Route
        path={`${contextPath}/advanced-protection/*`}
        element={<>{user && <AdvancedProtectionPage user={user} />}</>}
      />
      <Route
        path={`${contextPath}/less-secure-sign-ins/*`}
        element={<>{user && <LessSecureSignInPage user={user} />}</>}
      />
      <Route
        path={`${contextPath}/accounts/*`}
        element={<>{user && <ManageAccountsPage user={user} />}</>}
      />
    </Routes>
  );
};
