import React from 'react';
import './authenticationStatus.scss';
import {AuthenticationStatusProps} from "../../../../shared/interfaces";
import {Heading} from "../Heading/Heading";
import {Paragraph} from "../Paragraph/Paragraph";
import {Icon} from "../Icon/Icon";
import {Button} from "../Button/Button";
import {Toggle} from "../Toggle/Toggle";

export const AuthenticationStatus: React.FC<AuthenticationStatusProps> = (props) => {

  const getInteractionIfActionAvailable = () => {
    if (props.action?.type === "button" && !props.done) {
      return <div className={"action-wrapper"}>
        <Button id={props.id + "-button"} label={props.action.label} mode={props.action.mode} onClick={props.action.onClick}/>
      </div>;
    } else if (props.action?.type === "toggle") {
      return <div className={"action-wrapper"}>
        <Toggle id={props.id + "-toggle"} toggleState={props.action.toggleState} setToggleState={props.action.setToggleState} disabled={props.action.disabled}/>
      </div>;
    } else {
      return <></>;
    }
  };

  return (
      <div className={"authenticationStatus"}>
        <Icon icon={props.done ? "check-circle" : "x-circle"} sizeInPx={25}/>
        <div className={"authenticationStatus-content-side"}>
          <div className={"authenticationStatus-content-wrapper"}>
            <Heading text={props.headline} type={"h3"}/>
            <Paragraph text={props.description}/>
          </div>
          {
            getInteractionIfActionAvailable()
          }
        </div>
      </div>
  );

};
