import { useEffect } from 'react';
import {useLocation} from "react-router";

export const ScrollToTop = () => {
  const location =  useLocation();

  useEffect(() => {
    return () => {
      // Scrols to top on unmount.
      window.scrollTo(0, 0);
    };
  }, [location]);

  return null;
};