import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Navigation } from "designsystem/src/components/Navigation/Navigation";
import { contextPath } from "../utils/apiUrl";
import { NavigationEntryProps } from "../../../shared/interfaces";
import { openLinkSafely } from "designsystem/src/utils";
import i18next from "i18next";
import { useSwitchAccount } from "./AccountSwitchHandler";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import { useUser } from "./UserContext";

function baseNavEntries() : NavigationEntryProps[] {

  const baseNav: NavigationEntryProps[] = [{
    id: "navigation-userguide",
    icon: "book-open-01",
    i18nLabel: "navigation.userguide.label",
    onClick: () => openLinkSafely(window.origin + "/userguide"),
  }];

  if (window.globals.from === "intranet") {
    baseNav.push({
      id: "navigation-faq",
      icon: "message-chat-square",
      i18nLabel: "navigation.faq.label",
      onClick: openFaqUrl,
    });
  }

  return baseNav;
}

function openFaqUrl() {
  let url: string;
  if (i18next?.resolvedLanguage?.includes("de")) {
    url = "https://evarooms.merckgroup.com/Topic/EUS/de-de/projekte/moving-beyond-passwords/faq";
  } else {
    url =
      "https://evarooms.merckgroup.com/Topic/EUS/en-us/projects/moving-beyond-passwords/faq";
  }
  openLinkSafely(url);
}

export const NotAuthNavigation: FunctionComponent = () => {
  return <Navigation entries={baseNavEntries()} />;
};

export const AuthNavigation: FunctionComponent = () => {
  const switchAccount = useSwitchAccount();
  const {user} = useUser();
  const [cookies] = useCookies();
  const xsrfToken = cookies["XSRF-TOKEN"] || "";
  const formRef = useRef<HTMLFormElement>(null);
  const [navEntries, setNavEntries] =
    useState<NavigationEntryProps[]>(baseNavEntries());
  const location = useLocation();

  useEffect(() => {

    if (user) {
      sessionStorage.setItem("portalLoginName", JSON.stringify(user.userPrincipalName));
    } else {
      sessionStorage.removeItem("portalLoginName");
    }

    if (user && navEntries.length <= 3) {
      setNavEntries((oldNavWithoutUserStuff) => {
        const entries = [...oldNavWithoutUserStuff];
        if (!oldNavWithoutUserStuff.find(item => item.id === "navigation-logout")) {
          entries.push({
            id: "navigation-switchAccount",
            icon: "switch-horizontal-01",
            i18nLabel: "navigation.signInWithOtherAccount",
            onClick: () => switchAccount(),
          });
        }
        if (!oldNavWithoutUserStuff.find(item => item.id === "navigation-logout")) {
          entries.push({
            id: "navigation-logout",
                icon: "globe-01",
              i18nLabel: "navigation.logout",
              onClick: () => {
                formRef.current?.submit();
              }
          });
        }
        return entries;
      });
    }
  }, [user]);

  const isPasskeysPage = location.pathname === "/cp/passkeys";
  const isAccountsPage = location.pathname === "/cp/accounts";
  const isAdvancedProtectionPage = location.pathname === "/cp/advanced-protection";
  const isLessSecureSignInPage = location.pathname === "/cp/less-secure-sign-ins";
  const isSubpage = isPasskeysPage || isAccountsPage || isAdvancedProtectionPage || isLessSecureSignInPage;

  const subPageTitlesI18nMap: {[id:string]:string} = {
      "/cp/passkeys": "navigation.passkeys",
      "/cp/accounts": "navigation.accounts",
      "/cp/advanced-protection": "navigation.advancedProtection",
      "/cp/less-secure-sign-ins": "navigation.less-secure-sign-ins"
  };

  return (
    <>
      <Navigation
        subpage={isSubpage}
        title={isSubpage ? (subPageTitlesI18nMap[location.pathname]) : undefined}
        muid={(user?.preferredUsername) || undefined}
        username={user?.name || undefined}
        entries={navEntries}
      />
      <form action={`${contextPath}/logout`} method="post" ref={formRef}>
        <input type="hidden" name="_csrf" value={xsrfToken} />
      </form>
    </>
  );
};
