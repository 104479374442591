import {useCookies} from "react-cookie";
import {useGlobalState} from "../GlobalState";
import {enforceFidoType} from "../../../shared/interfaces";
import {httpGet, httpPost} from "./fetch-client";
import {ErrorResponse} from "../interfaces/types";
import {apiPath} from "./apiUrl";
import {signInFunction} from "../pages/LoginPage";
import {DeleteFidoRequest, FidoDataResponse} from "../pages/FidoCredentials";
import {useFetchToken} from "./portalToken";

export const useFetchDisableAdvancedProtectionPage = (updateFidoData: (data: FidoDataResponse) => void | undefined, setIsInFidoMigrationGroupState:(b:boolean|undefined) => void  , userName: string) => {
  const [cookies] = useCookies();
  const [, setGlobalError] = useGlobalState("error");
  const [, setIsLoading] = useGlobalState("isLoading");
  const fetchToken = useFetchToken();
  const fetchUserFido = useFetchUserFido();

  const enforceFido = async (
      enforceFidoValue: enforceFidoType,
      reason: string,
  ) => {
    setIsLoading({ loadingType: "standard" });

    if (enforceFidoValue === "disable") {
      const token = await fetchToken("DisableAdvancedProtectionAction",{reason});
      window.location.assign("/disableAdvancedProtection?factor=FIDO2_USER_VERIFICATION&upnToAuthenticate=" + decodeURIComponent(userName) + "&ptoken=" + token);
    } else {

      try {
        const {status, error, payload} = await httpPost<string | ErrorResponse>(
            `${apiPath}/user/fido/enforce/enable`,
            {reason: reason},
            cookies,
        );

        if (status === 401) {

          console.log("status code 401");
          signInFunction();
        }

        // Check for any error in response
        if (error) {
          setGlobalError(payload ?? "REQUEST_FAILED");
          return false;
        }
      } finally {
        setIsInFidoMigrationGroupState(true);
        setIsLoading({loadingType: null});
      }

      const res = await fetchUserFido();
      if (res && updateFidoData) {
        updateFidoData(res);
      }
      return true;
    }
  };

  return enforceFido;
};

export const useFetchUserFido = () => {
  const [, setIsLoading] = useGlobalState("isLoading");
  const [cookies] = useCookies();
  const [, setGlobalError] = useGlobalState("error");

  return async () => {
    setIsLoading({loadingType: "standard"});
    let res = undefined;
    try {
      const {status, error, payload} = await httpGet<FidoDataResponse>(
          `${apiPath}/user/fido`,
          cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
            (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return;
      }

      if (status == 200 && payload) {
        res = payload;
      }
    } finally {
      setIsLoading({loadingType: null});
    }
    return res;
  };
};

export const useDeleteFido = (updateFidoData: (data: FidoDataResponse) => void  |undefined) => {
  const [, setIsLoading] = useGlobalState("isLoading");
  const [, setModal] = useGlobalState("modal");
  const [cookies] = useCookies();
  const [, setGlobalError] = useGlobalState("error");
  const fetchUserFido = useFetchUserFido();

  return async (logicalKeyId: string) => {
    setModal(undefined);
    setIsLoading({loadingType: "standard"});
    try {
      const body: DeleteFidoRequest = {
        logicalKeyId: logicalKeyId,
      };
      const {status, error, payload} = await httpPost<string | ErrorResponse>(
          `${apiPath}/user/fido/delete`,
          body,
          cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(payload ?? "REQUEST_FAILED");
        return false;
      }
    } finally {
      setIsLoading({loadingType: null});
    }

    const res = await fetchUserFido();
    if (res && updateFidoData) {
       updateFidoData(res);
    }
    return true;
  };
};
